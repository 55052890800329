import React, { useEffect } from 'react';
import * as echarts from 'echarts';

interface Props {
    dataX: Array<number | string>,
    dataY: Array<number>,
    width: number,
    height: number,
    isShow?: boolean,
}

/**
 * 图表-柱状图
 * @datax Array<number | string>, x轴数据
 * @datay Array<number>, y轴数据
 * @width number, 容器宽度
 * @height numebr, 容器高度
 * @isShow boolean, 是否显示图表坐标轴等信息
 */

const BarChart = (props: Props) => {
    const id = new Date().getTime().toString() + Math.random();
    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                tooltip: {
                    show: props.isShow,
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'line',  // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: props.dataX,
                    axisLabel: {
                        show: props.isShow,
                        color: '#f8f8f8',
                    },
                    axisLine: {
                        show: props.isShow,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    axisTick: {
                        show: props.isShow,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    splitLine: {
                        show: props.isShow,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: props.isShow,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    axisLabel: {
                        show: props.isShow,
                        color: '#f8f8f8',
                    },
                    axisLine: {
                        show: props.isShow,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                },
                grid: {
                    left: props.isShow ? '2%' : '2%',
                    right: '2%',
                    top: props.isShow ? '5%' : '2%',
                    bottom: props.isShow ? '6%' : '2%',
                    containLabel: props.isShow,
                },
                series: [{
                    data: props.dataY,
                    type: 'bar',
                    itemStyle: {
                        color: '#4992ff',
                        barBorderRadius: props.isShow ? [0, 0, 0, 0] : [10, 10, 0, 0],
                    }
                }]
            };
            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])


    return (
        <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} />
    )
}

export { BarChart }