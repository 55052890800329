import service from '../service'
const Config = {
    getProjectInfo: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Project/GetProjectInfo',
            params: { lineCode }
        })
    },

    getRobots: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Robot/GetRobots',
            params: { lineCode }

        })
    },

    getCameras: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Camera/GetCameras',
            params: { lineCode }
        })
    },

    getServerCompiledVersion: (): Promise<any> => {
        return service({
            url: '/Project/GetServerCompiledVersion'
        })
    },

    getOvenOrRefrigerators: (lineCode?: string): Promise<any> => {
        return service({
            url: '/OvenOrRefrigerator/GetOvenOrRefrigerators',
            params: { lineCode }
        })
    },

    getGlueBuckets: (lineCode?: string): Promise<any> => {
        return service({
            url: '/GlueBucket/GetGlueBuckets',
            params: { lineCode }
        })
    },

    getHasStopperWorkStations: (lineCode?: string): Promise<any> => {
        return service({
            url: '/HasStopperWorkStation/GetHasStopperWorkStations',
            params: { lineCode }
        })
    },
}

export default Config
