import { Fragment } from 'react'
import { LiquidfillChart } from '@/components/charts/liquidfill'
import { LineLayout } from '@/layout'
import { DigitalBlock } from '@/components/digitalBlock'
import { useRequest } from 'ahooks'
import * as API from '@/api/request'
import { searchParams } from '@/utils'
import { Loading2 } from '../loading'
import { Error } from '../error'
import { round } from 'lodash'
const lineCode = searchParams().lineCode || null
export const Quality = () => {
  const { loading, data, error } = useRequest(() => API.Quality.getTodayFirstPassYield(lineCode))
  if (loading) return <Loading2 />
  if (error) return <Error size={0.5}/>
  return <Fragment>
    <LineLayout justify='start' direction='column' gap={15} margin={[15, 0, 0, 0]}>
      <LiquidfillChart data={data} width={120} height={100} />
      <DigitalBlock value={round(data * 100)} name='直通率' unit='%' size={0.8} spacing={1} />
    </LineLayout>
  </Fragment>
}
