import { LineLayout } from '@/layout'
import { DigitalBlock } from '../digitalBlock'
import { useRequest } from 'ahooks'
import * as API from '@/api/request'
import { Loading2 } from '../loading'
import { searchParams } from '@/utils'
import { Empty } from '@/components/empty'
const lineCode = searchParams().lineCode
const callTypeToName = {
    '0': '设备',
    '1': '质量',
    '2': '物料',
    '3': '人员'
}
export const Call = () => {
    const { loading, data } = useRequest(() => {
        return Promise.all([
            API.Call.getTodayCallCount(lineCode),
            API.Call.getCallingRecords(lineCode)
        ])
    })

    if (loading) return <Loading2 />
    const [todayCallCount, callingRecords] = data as any
    let records = callingRecords.map((item: any) => `${item.workStationName}-${callTypeToName[item.callType]}`)
    return <LineLayout justify='between' width={345} direction='column'>
        <LineLayout justify='between' width={345} padding={[10, 15, 0, 10]}>
            {
                todayCallCount.map((item: any) => {
                    return <DigitalBlock value={item.totalCount} name={callTypeToName[item.callType]} type='nameStacking' unit='次' spacing={10} size={0.8} />
                })
            }
        </LineLayout>
        {
            records.length !== 0 ? <LineLayout justify='start' direction='column' width={345} alignItems='flex-start' padding={[10, 0, 10, 10]} >
                {

                    records.map((item: any) => {
                        return <div style={{ width: '100%', lineHeight: '30px', fontSize: '16px' }} >{item}</div>
                    }).filter((item: any, idx: number) => {
                        return idx < 4
                    })
                }
            </LineLayout> : <Empty value='无呼叫中的信息' />
        }

    </LineLayout>

}