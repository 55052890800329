import { BasicLayout, LineLayout } from '@/layout'
import { TimeBar } from "@/components/bar"
import { MiniCard } from "@/components/card"
import { Card } from '@/components/card'
import { useRequest } from 'ahooks'
import { useState } from 'react'
import * as API from '@/api/request'
import { Loading1 } from '@/components/loading'
import { handleDataForEasyChart } from '@/utils'
import _ from 'lodash'
import { BarChart, BasicLineChart, StackedLineChart } from '@/components/charts'
import { formatLineChartXAxis, getTimeRangeByUrl } from '@/utils/time'
import FormatterType from '@/components/charts/formatterType';
const pageName = '车间质量'
const legend = ['产线1', '产线2', '产线3', '产线4', '产线5', '产线6']
const getAllLineChartDataY = (data: Array<any>) => {
    return data.map(item => {
        return {
            name: `产线${item.lineCode}`,
            data: item.values.map((child: any) => child.count)
        }
    })
}
export const Quality = () => {
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    const { data, loading } = useRequest(() => {
        return Promise.all([
            API.Quality.getFirstPassYieldByTimeRange(null, timeRange),
            API.Quality.getFirstPassYieldByTimeRangeByLineCode(timeRange),
            API.Quality.getStatisticValues(null, timeRange),
            API.Quality.getNotGoodCountByDefectTypes(null, timeRange),
            API.Quality.getNotGoodCountByTimeRange(null, timeRange)
        ])
    }, { refreshDeps: [timeRange] })
    if (loading) return <BasicLayout name={pageName}>
        <TimeBar change={(timeRange: any) => setTimeRange(timeRange)} size={0.8} />
        <Loading1 />
    </BasicLayout>
    const [firstPassYield, allLineFirstPassYield, statisticValues, notGoodCountByDefectTypes, notGoodCount] = data
    console.log(notGoodCountByDefectTypes)
    const timeRangeType = (timeRange as TimeRange).timeRangeType
    return <BasicLayout name={pageName}>
        <TimeBar change={(timeRange: any) => setTimeRange(timeRange)} size={0.8} />
        <LineLayout justify='between' direction='column' >
            <MiniCard title='直通率' size='long' unit='%' icon='direct.png' width={355} value={_.round(statisticValues.firstPassYield * 100, 1)} height={80} margin={[10, 0, 0, 0]} />
            <LineLayout justify='between' width={355} direction='row' >
                <MiniCard title='良品数' icon='goodProduct.png' unit='双' width={170} value={statisticValues.notGoodCount} height={80} margin={[10, 0, 0, 0]} />
                <MiniCard title='缺陷次数' icon='badProduct.png' unit='双' width={170} value={statisticValues.okCount} height={80} margin={[10, 0, 0, 0]} />
            </LineLayout>
            <LineLayout justify='start' direction='column' width={355} gap={10} margin={[10, 0, 0, 0]} >
                <Card title='直通率%' icon='direct.png'>
                    <BasicLineChart
                        width={355}
                        height={200}
                        dataX={handleDataForEasyChart(firstPassYield, timeRangeType).dataX}
                        dataY={handleDataForEasyChart(firstPassYield, timeRangeType).dataY}
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null} />
                </Card>
                <Card title='各产线直通率%' width={350} height={280} icon='direct.png'>
                    <StackedLineChart legend={legend} width={355} height={230} dataX={formatLineChartXAxis((timeRange as any).timeRangeType, allLineFirstPassYield[0].values)}
                        dataY={getAllLineChartDataY(allLineFirstPassYield)} />
                </Card>
                <Card title='缺陷次数(次)' width={350} height={280} icon='badProduct.png'>
                    <BasicLineChart
                        width={355}
                        height={200}
                        dataX={handleDataForEasyChart(notGoodCount, timeRangeType).dataX}
                        dataY={handleDataForEasyChart(notGoodCount, timeRangeType).dataY}
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null} />
                </Card>

                <Card title='缺陷类型(次)' width={350} height={280} icon='badProduct.png'>
                    <BarChart dataX={[1, 2, 3]} dataY={[1, 2, 3]} height={230} width={350} isShow />
                </Card>
            </LineLayout>
        </LineLayout>
    </BasicLayout>
}
