import { BasicLayout, LineLayout } from '@/layout'
import { Image } from '@/components/image'
import { Button , Modal} from 'antd-mobile'

import { useHistory } from 'react-router-dom'
import './index.scss'
import { useState } from 'react'
import { values } from 'lodash'
export const Login = () => {
    const [inputPassword, setInputPassword] = useState("")
    const password = "zs123"
    const history = useHistory()
    const clickJumpTo = () => {
        return history.push('/home')
    }

    const handleInputChange = (event) => {  
        setInputPassword(event.target.value); // 在输入框内容改变时更新状态  
      };  
    const login = () =>{
        if(inputPassword.toLocaleLowerCase()===password) {
            localStorage.setItem('ZsTekWebKanbanKey', password);
            Modal.alert("","登录成功")
            window.location.reload()
            
        } else {
            Modal.alert("","密码错误，请重新输入")
        }
        
    }
    return <BasicLayout name='登录' >
        <LineLayout justify='start' direction='column' margin={[50, 0, 0, 0]} >
            <input className='input-password' onChange={handleInputChange}  placeholder='请输入登录密码' type='password'></input>
          <Button type="primary" style={{width:350,marginTop:30}} onClick={login}>点击登录</ Button>  
           
        </LineLayout>
    </BasicLayout >
}