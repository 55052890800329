import { Fragment } from 'react'
import { PieChart } from '../charts'
import { EasyGridLayout, LineLayout } from '@/layout'
import { DigitalBlock } from '../digitalBlock'
interface TodayCountPieChartProps {
    data: Array<any>
}
export const TodayCountPieChart = (props: TodayCountPieChartProps) => {

    let columns:number =Math.ceil(props.data.length/2)     
    return <Fragment>
        <LineLayout justify='start' direction='column'   >
            <EasyGridLayout width={320} height={65*columns } columns={2} rows={columns} splitLine={true} center={true}>
                {
                    props.data.map(item=>{
                        return  <DigitalBlock width={150} name={item.name} value={item.value} unit='双' fontSize={36} size={0.65} type='nameStacking' />
                    })
                }
               
            </EasyGridLayout>
            <PieChart width={350} height={190} data={props.data} ></PieChart>
        </LineLayout>
    </Fragment>
}
