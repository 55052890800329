import React, { useState } from 'react';
import './index.scss'
import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout'
import { Card, MiniCard } from '@/components/card';
import { DigitalBlock } from '@/components/digitalBlock';
import { Icon } from '@/components/icon';
import * as API from '@/api/request';
import * as Charts from '@/components/charts';
import { handleDataForLineChart, searchParams } from '@/utils';
import { useRequest } from 'ahooks';
import { EnvironmentApi } from '@/api/request';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { formatLineChartXAxis, getTimeRangeByUrl } from '@/utils/time';
import _ from 'lodash';
import { ExportButton } from '@/components/exportButton';
import FormatterType from '@/components/charts/formatterType';
import { Loading1 } from '@/components/loading';
import { DatePicker } from '@/components/datepicker';
import moment from 'moment';
import { Line } from '@/components/thumbnail';
import { Button } from 'antd-mobile';
import { Loading2 } from '@/components/loading';
import { AnyRecord } from 'dns';
const Capacity = () => {
    const lineCode = searchParams().lineCode;
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    const [capacityByDate, setCapacityByDate] = useState([])
    const [isShowHistoryCapacity, setHistoryCapacity] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [capacityByDateLoading, setCapacityByDateLoading] = useState(false)
    //获取页面数据
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Production.getTodayCapacity(lineCode),
            API.Production.getProductionHourStatisticsByMinutesCacl(lineCode),
            API.Production.getHistoryHightestMaxCapacityPerHourInDay(lineCode),
            API.Production.getThisYearMaxCapacityPerHourInDay(lineCode)
        ])
    })
    const pageName = '产能'
    if (loading) {
        return <BasicLayout name={pageName}>
            <Loading1></Loading1>
        </BasicLayout>
    }
    if (error) {
        return <BasicLayout name={pageName}>
            <Error></Error>
        </BasicLayout>
    }
    const staticCount: any = data[1]
    const maxHistoryCount = (data[2] as any)[0].maxCapacityPerHour
    const lastHourCount = staticCount.lastHourCount
    const maxAmountOfOneHour = staticCount.maxAmountOfOneHour
    const maxCapacityPerHourInDay = data[3] as any
    const handleChartData = (data: any) => {
        let dataY = data.map((item: any) => { return item.count })
        let dataX = data.map((item: any) => { return moment(item.timeRange.endTime).format("HH:mm") })
        return {
            dataX,
            dataY
        }
    }
    //处理一下按日返回的最高产能数据  格式有点不一样 单独处理一下
    const getCapacityByDate = () => {
        setCapacityByDateLoading(true)
        API.Production.getCapacityByDate(lineCode, selectedDate).then((res: any) => {
            setCapacityByDate(res)
            setCapacityByDateLoading(false)
            setHistoryCapacity(true)
        })
    }
    return (
        <BasicLayout name={pageName} >
            <p className='title'>实时产能</p>
            <LineLayout justify='around' margin={[0, 0, 10, 0]}>
                <MiniCard value={lastHourCount} unit={'双/小时'} icon='capacity.png' title={'最近一小时'} height={80} width={170} />
                <MiniCard value={maxAmountOfOneHour.count} unit={'双/小时'} icon='capacity.png' title={'今日最高'} height={80} width={170} />
            </LineLayout>
            <Card title="今日产能趋势" icon="line.png" >
                <Charts.BasicLineChart width={355} height={200}
                    dataX={handleChartData(data[0]).dataX}
                    dataY={handleChartData(data[0]).dataY}
                    formatter={null}
                />
            </Card>



            <p className='title'>历史汇总</p>
            <LineLayout justify='around' margin={[0, 0, 10, 0]}>
                <MiniCard value={maxHistoryCount} unit={'双/小时'} icon='capacity.png' title={'历史最高'} height={80} width={170} />
                <MiniCard value={null} unit={'双/小时'} icon='capacity.png' title={'本月最高'} height={80} width={170} />
            </LineLayout>

            <Card title='今年每日最高产能趋势' icon='line.png'>
                <Charts.BasicLineChart width={355} height={200}
                    dataY={maxCapacityPerHourInDay.map((item: any) => { return item.maxCapacityPerHour })}
                    dataX={maxCapacityPerHourInDay.map((item: any) => { return moment(item.timeRange.endTime).format("MM/DD") })}
                    formatter={null}
                />
            </Card>

            <p className='title'>过往查询</p>
            <Card padding={[0, 10, 0, 0]} height={100}>
                <LineLayout justify='between' padding={[0, 15, 0, 10]}>
                    <DatePicker label='日期' defaultDate='2023-05-15' change={(date) => { setSelectedDate(date) }} />
                    <Button type='primary' size='small' style={{ marginLeft: '10px' }} onClick={getCapacityByDate}>查询</Button>
                </LineLayout>
                {/* <DatePicker label='结束日期' defaultDate=''></DatePicker> */}
            </Card>

            {
                isShowHistoryCapacity ?
                    <Card margin={[10, 0, 0, 0]} title="实时产能趋势" height={'300'} icon="line.png">
                        {
                            !capacityByDateLoading ? <Charts.BasicLineChart width={355} height={200}
                                dataX={handleChartData(capacityByDate).dataX}
                                dataY={handleChartData(capacityByDate).dataY}
                                formatter={null}
                            /> : <Loading2></Loading2>
                        }
                    </Card> : null
            }
        </BasicLayout>
    )
}

export { Capacity }