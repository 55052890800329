import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout'
import { Card, MiniCard } from '@/components/card';
import * as Charts from '@/components/charts';
import { useRequest } from 'ahooks';
import * as API from '@/api/request';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { formatLineChartXAxis } from '@/utils/time';
import _ from 'lodash';
import { store } from '@/store'
import FormatterType from '@/components/charts/formatterType';
const getChartData = (environmentName: string, data: Array<any>) => {
    return {
        dataX: formatLineChartXAxis(0, data),
        dataY: data.map(item => {
            if (!!item.values) {
                //湿度特殊处理一下
                if (environmentName === 'humidness') {
                    return _.round(item.values.humidness * 100)
                }
                return _.round(item.values[environmentName])
            }
            return null
        })
    }
}

const Environment = () => {

    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Environment.getCurrentEnvironmentValues(),
            API.Environment.getEnvironmentValuesByTimeRange()
        ])
    })


    const pageName = store.getState().projectInfo.shortCode + '环境管理'
    if (loading) return <BasicLayout name={pageName}> <Loading /> </BasicLayout>
    if (error) return <BasicLayout name={pageName}> <Error /> </BasicLayout>
    let [environments, environmentsByChart]: any = data;
    const miniCards = [
        { title: '温度', icon: 'temperature.png', value: environments.temperatureInC },
        { title: '湿度', icon: 'humidity.png', value: environments.humidness * 100 },
        { title: '噪音', icon: 'noise.png', value: environments.noiseIndB },
        { title: 'pM2.5', icon: 'PM25.png', value: environments.pM25 },
    ]
    const chartsData = [
        { title: '温度', ...getChartData('temperatureInC', environmentsByChart), },
        { title: '湿度', ...getChartData('noiseIndB', environmentsByChart), },
        { title: '噪音', ...getChartData('humidness', environmentsByChart), },
        { title: 'pM2.5', ...getChartData('pM25', environmentsByChart), }
    ]
    return (
        <BasicLayout name={pageName}>
            <EasyGridLayout width={355} height={175} rows={2} columns={2} padding={[0, 0, 0, 0]} center gap={10}>
                {
                    miniCards.map(item => <MiniCard title={item.title} icon={item.icon} value={item.value} />)
                }
            </EasyGridLayout>

            <LineLayout justify='around' padding={[10, 0, 15, 0]} gap={10} direction='column'>
                {
                    chartsData.map((item: any, idx: number) => {
                        return <Card height={250} title={item.title} icon='line.png'>
                            <Charts.BasicLineChart width={355} height={200} dataX={item.dataX} dataY={item.dataY} formatter={FormatterType.TodayMoment} />
                        </Card>
                    })
                }
            </LineLayout>
        </BasicLayout>
    )
}

export { Environment }