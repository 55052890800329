import service from '../service'
import moment from 'moment'

const today = { beginTime: moment().format('YYYY-MM-DD'), endTime: moment().add(1, 'day').format('YYYY-MM-DD') }
const Quality = {
  /**
   * 获取今天的缺陷次数根据错误类型
   * @param lineCode 
   * @returns 
   */
  getTodayNgCountByDefectTypes: (lineCode?: string) => {
    return service({
      url: '/Quality/GetNotGoodCountByDefectTypes',
      params: { lineCode: lineCode, ...today, timeRangeType: 2 }
    })
  },
  /**
   * 获取今日直通率
   * @param lineCode 
   * @returns 
   */
  getTodayFirstPassYield: (lineCode?: string): Promise<any> => {
    return service({
      url: '/Quality/GetTodayFirstPassYield',
      params: { lineCode: lineCode }
    })
  },


  /**
   * 获取今日缺陷次数
   * @param lineCode 
   * @returns 
   */
  getTodayNotGoodCount: (lineCode?: string): Promise<any> => {
    return service({
      url: '/Quality/GetTodayNotGoodCount',
      params: { lineCode: lineCode }
    })
  },


  /**
   * 获取今日缺陷次数
   * @param lineCode 
   * @returns 
   */
  getNotGoodCountByTimeRangeByLineCode: (lineCode?: string): Promise<any> => {
    return service({
      url: '/Quality/GetNotGoodCountByTimeRangeByLineCode',
      params: { lineCode: lineCode, ...today }
    })
  },



  /**
   * 获取质量页面的一些统计值
   * @param lineCode 
   * @returns 
   */
  getStatisticValues: (lineCode?: string, timeRange?: any, workStationCode?: string): Promise<any> => {
    return service({
      url: '/Quality/GetStatisticValues',
      params: { lineCode: lineCode, ...timeRange }
    })
  },



  /**
   * 产线直通率
   * @param lineCode 
   * @returns 
   */
  getFirstPassYieldByTimeRange: (lineCode?: string, timeRange?: any, workStationCode?: string): Promise<any> => {
    return service({
      url: '/Quality/GetFirstPassYieldByTimeRange',
      params: { lineCode: lineCode, ...timeRange, workStationCode }
    })
  },


  /**
   * 车间各产线的直通率
   * @param lineCode 
   * @param timeRange 
   * @returns 
   */

  getFirstPassYieldByTimeRangeByLineCode: (timeRange?: any): Promise<any> => {
    return service({
      url: '/Quality/GetFirstPassYieldByTimeRangeByLineCode',
      params: { ...timeRange }
    })
  },


  /**
   * 根据时间和产线编号获取缺陷次数
   * @param lineCode 
   * @param timeRange 
   * @returns 
   */
  getNotGoodCountByTimeRange: (lineCode?: string, timeRange?: any): Promise<any> => {
    return service({
      url: '/Quality/GetNotGoodCountByTimeRange',
      params: { lineCode: lineCode, ...timeRange }
    })
  }
  ,


  /**
   * 根据时间和产线编号获取缺陷次数
   * @param lineCode 
   * @param timeRange 
   * @returns 
   */
  getNotGoodCountByDefectTypes: (lineCode?: string, timeRange?: any, workStationCode?: string): Promise<any> => {
    return service({
      url: '/Quality/getNotGoodCountByDefectTypes',
      params: { lineCode: lineCode, ...timeRange, workStationCode }
    })
  }
  ,

  /**
   * 获取最新的检测记录
   * @param {number} lineCode
   * @param {number} workStationCode 
   */
  getLastQualityCheckRecord: (lineCode?: string, workStationCode?: string): Promise<any> => {
    return service({
      url: '/Quality/GetLastQualityCheckRecord',
      params: { lineCode: lineCode, workStationCode }
    })
  }
  ,

  /**
   * 获取品质检测图片的相对路径
   * @param {number} qualityCheckRecordId
   */
  getQualityCheckPictureRelativeUrls: (qualityCheckRecordId?: number): Promise<any> => {
    return service({
      url: '/Quality/GetQualityCheckPictureRelativeUrls',
      params: { qualityCheckRecordId }
    })
  }

  // /GetFirstPassYieldByTimeRangeByLineCode

  ////GetNotGoodCountByDefectTypes
  //GetNotGoodCountByTimeRange
  ///

}

export default Quality



