import React, { useEffect, useState } from "react";
import './index.scss';
import moment, { Moment } from "moment";
import classNames from "classnames";
import { values } from "lodash";
import { Button } from "antd-mobile";
import { isAbsolute } from "path";
import { useMount } from "ahooks";

interface DatePickerProps {
    change?: Function,
    label: String,
    defaultDate:string,
}



export const DatePicker = (props: DatePickerProps) => {
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [date, setDate] = useState('2023-05-11')
    const [monthAllDate, setMonthAlldate] = useState([])
    const [isShowPanel, setShowPanel] = useState(false)
    const [defaultDate, setDefaultDate] = useState(props.defaultDate)
    const [isSelected, setSelected] = useState(false)
    const arabicNumeralsToChinese = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']
    //根据日期获取日期所在月份的的数据并
    //且再数据中标记当前日期
    const generatorCurrentMonthData = (date: string) => {
        let currentYear = moment(date).format("YYYY")
        let currentMonth = moment(date).format("MM")
        let currentMonthFirstWeekday = moment(currentYear + '-' + currentMonth + '-' + '01').weekday()
        let currentMonthDayCount = moment(date).daysInMonth();
        let currentMonthAllDate = []
        if (currentMonthFirstWeekday === 0) currentMonthFirstWeekday = 7
        //一排7天 预留6排空间


        for (let i = 0; i < 7 * 6; i++) {
            if (i < currentMonthFirstWeekday - 1) {
                currentMonthAllDate.push(null)
            } else if (i > currentMonthDayCount - 1 + currentMonthFirstWeekday - 1) {
                currentMonthAllDate.push(null)
            } else {
                let isDefault = defaultDate === `${currentYear}-${currentMonth}-${i - currentMonthFirstWeekday + 2}`
                //未来时间无法点击 置灰
                //disabled

                currentMonthAllDate.push({
                    value: i - currentMonthFirstWeekday + 2,
                    selected: false,
                    isDefault,
                    disabled:false
                })
            }
        }
        return {
            year: currentYear,
            month: currentMonth,
            monthFirstWeekDay: currentMonthFirstWeekday,
            monthDayCount: currentMonthDayCount,
            monthAllDate: currentMonthAllDate
        }
    }

    //点击显示日期面板 需要初始化这个月的数据
    const showDatePickerPanel = () => {
        //这个数据应该是当前界面上的数据
        let monthData = generatorCurrentMonthData(defaultDate)
        setYear(monthData.year)
        setMonth(monthData.month)
        setMonthAlldate(monthData.monthAllDate)
        setShowPanel(true)
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    }

    //点击切换上个月
    const clickChangePreMonth = () => {
        const preMonthDate = moment(date).subtract(1, 'month').format('YYYY-MM-DD')
        //用来生成月份数据的日期和当前选中的日期不是同一个日期
        let monthData = generatorCurrentMonthData(preMonthDate)
        setMonth(monthData.month)
        setYear(monthData.year)
        setDate(preMonthDate)
        setSelected(false)
        setMonthAlldate(monthData.monthAllDate)
    }
    //点击切换下个月 
    const clickChangeNextMonth = () => {
        const preMonthDate = moment(date).add(1, 'month').format('YYYY-MM-DD')
        console.log(preMonthDate)
        let monthData = generatorCurrentMonthData(preMonthDate)
        setMonth(monthData.month)
        setYear(monthData.year)
        setDate(preMonthDate)
        setSelected(false)
        setMonthAlldate(monthData.monthAllDate)
    }
    //点击选中日期
    const clickSelectDate = (date: any) => {
        setSelected(true)
        let newMonthAllDate = monthAllDate.map(item => {
            if (item !== null) {
                item.selected = false
                if (item.value === date.value) {
                    setDate(year + '-' + month + '-' + item.value)
                    console.log(year + '年' + month + '月' + item.value + '日')
                    item.selected = true
                }
            }
            return item
        })
        setMonthAlldate(newMonthAllDate)
    }

    //点击关闭当前面板
    const clickClosePanel = () => {
        setSelected(false)
        setShowPanel(false)
        document.getElementsByTagName('body')[0].style.overflow = 'auto'

    }

    //点击确认 修改default
    const clickConfirm = () => {
        setDefaultDate(date)
        if(props.change) {
            props.change(date)

        }
        setShowPanel(false)
        document.getElementsByTagName('body')[0].style.overflow = 'auto'

    }
    return <div>
        <div className="date-select-content">
            <div className="date-label">{props.label}</div>
            <div className="date-input" onClick={() => { showDatePickerPanel() }}>{defaultDate}</div>
        </div>

        {
            isShowPanel ? <div className="datepicker-content">
                <div className="datepicker-container">
                    <div className="datepicker-head">
                        <div className="left-btn" onClick={clickChangePreMonth}></div>
                        <div className="current-month">
                            {arabicNumeralsToChinese[parseInt(month) - 1] + ' 月'}
                            {/* <div className="year">{year}</div> */}
                        </div>
                        <div className="right-btn" onClick={clickChangeNextMonth}></div>
                    </div>
                    <div className="datepicker-body">
                        <ul className="week">
                            <li>一</li>
                            <li>二</li>
                            <li>三</li>
                            <li>四</li>
                            <li>五</li>
                            <li>六</li>
                            <li>日</li>
                        </ul>
                        <ul className="date">
                            {
                                monthAllDate.map(item => {
                                    if (item === null) {
                                        return <li></li>
                                    }
                                    return <li onClick={() => { clickSelectDate(item) }} className={classNames({
                                        'date-selected': item.selected,
                                        'date-default': item.isDefault,
                                        'date-disable':false
                                    })}>{item.value}</li>
                                })
                            }

                        </ul>
                    </div>

                    <div className="datepicker-foot">
                        <div className="year">{year}年</div>
                        <div className="btns">
                            <Button onClick={() => { clickClosePanel() }} size="small" style={{ marginRight: "10px" }} >关闭</Button>
                            <Button disabled={!isSelected} onClick={() => { clickConfirm() }} type="primary" size="small">确定</Button>
                        </div>
                    </div>
                </div>
            </div> : null
        }
    </div>
}