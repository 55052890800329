import { GaugeChart } from "@/components/charts";
import { DigitalBlock } from "../digitalBlock";
import { LineLayout } from '@/layout'
import { useRequest } from "ahooks";
import * as API from '@/api/request'
import { searchParams } from "@/utils";
import { Loading2 } from "../loading";
import { Error } from "../error";
import { round } from "lodash";
const lineCode = searchParams().lineCode
export const Equipment = () => {
  const { loading, error, data } = useRequest(() => API.Equipment.getTodayUtilizationRate(lineCode))
  if (loading) return <Loading2 />
  if (error) return <Error size={0.5} />
  return (
    <LineLayout justify='start' direction='column' margin={[15, 0, 0, 0]}>
      <GaugeChart.BasicGaugeChart
        data={data * 100}
        width={120}
        height={110}
      />
      <DigitalBlock value={round(data * 100)} unit='%' name='稼动率' spacing={1} size={0.8} />
    </LineLayout>
  );
};
