import { BasicLineChart } from "@/components/charts";
import { DigitalBlock } from "@/components/digitalBlock";
import { LineLayout } from '@/layout'
import { useRequest } from "ahooks";
import * as API from '@/api/request'
import { handleDataForEasyChart, searchParams } from "@/utils";
import { Loading2 } from "@/components/loading";
import { Error } from "@/components/error";
import { Fragment } from "react";
import moment from "moment";
import { timeRanges } from "@/utils/time";
import './index.scss';
export const Production3 = () => {
    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Production.getCountByLast15Days(searchParams().lineCode),
            API.Production.getTotalCount(searchParams().lineCode),
            API.Production.getTodayCount(searchParams().lineCode),
            API.Equipment.getlectricityValues(searchParams().lineCode, timeRanges.today()),
            API.Equipment.getCurrentElectricityPowerInW(searchParams().lineCode),
        ])
    })
    if (loading) return <Loading2 />
    if (error) return <Error size={0.5} />
    const [countBy15days, totalCount, todayCount, electricityValues, powerInW] = data
    console.log(countBy15days)
    return (
        <Fragment>
            <LineLayout justify='start' direction='column' margin={[0, 0, 0, 0]} width={248}  >
                <LineLayout justify='start' gap={0} margin={[10, 0, 0, 20]} width={248}>
                    <DigitalBlock
                        width={210}
                        size={0.6}
                        value={totalCount}
                        unit='双'
                        fontSize={50}
                        name='累积产量'
                        type='nameStacking'
                        spacing={10} />
                    <DigitalBlock
                        size={0.6}
                        value={todayCount}
                        unit='双'
                        fontSize={50}
                        name='今日产量'
                        type='nameStacking'
                        spacing={10} />
                </LineLayout>
                <LineLayout justify='start' gap={0} margin={[20, 0, 10, 20]} width={248}>
                    <DigitalBlock
                        width={210}
                        size={0.6}
                        value={powerInW / 1000}
                        unit=' kW'
                        fontSize={50}
                        name='当前功率'
                        type='nameStacking'
                        spacing={10} />
                    <DigitalBlock
                        color='orange'
                        size={0.6}
                        value={electricityValues.quantityInKWH}
                        unit=' 度'
                        fontSize={50}
                        name='今日能耗'
                        type='nameStacking'
                        spacing={10} />
                </LineLayout>
                <div className='chart-title' style={{ width: '225px' }}>最近15日产量(双)</div>
                <BasicLineChart
                    // hideYAxis
                    width={245}
                    height={170}
                    dataX={countBy15days.map((item: any) => moment(item.timeRange.beginTime).format('DD'))}
                    dataY={handleDataForEasyChart(countBy15days).dataY} />
            </LineLayout>
        </Fragment>
    );
};
