import { Icon } from '../icon'
import './loading.scss'
export const Loading1 = () => {
    return (
        <div className='loading'>
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    )
}

export const Loading2 = () => {
    return <div className='loading-2'>
        <div className='loading-2-content rotate'>
            <Icon iconName='loading.png' size={1.4} />
        </div>
    </div>
}

