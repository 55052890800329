import React, { useState } from 'react';
import './index.scss';
import { TimeBar, TimeBar2 } from '@/components/bar';
import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout'
import { Card } from '@/components/card';
import * as Charts from '@/components/charts';
import { searchParams, handleDataForLineChart, handleDataForTreemap } from '@/utils';
import { getTimeRangeByUrl } from '@/utils/time'
import { useRequest } from 'ahooks';
import * as API from '@/api/request';
import { Error } from '@/components/error';
import { MiniCard } from '@/components/card';
import _ from 'lodash';
import { Loading1 } from '@/components/loading';
import { ExportButton } from '@/components/exportButton';
import FormatterType from '@/components/charts/formatterType';
const Production = () => {
const lineCode = searchParams().lineCode || null;

    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    //获取页面数据
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Production.getStatisticValues(lineCode, timeRange),
            API.Production.getCapacityInPairPerHourByTimeRange(lineCode, timeRange),
            API.Production.getCountByTimeRange(lineCode, timeRange),
            API.Production.getCountByShoeType(lineCode, timeRange),
        ])
    }, { refreshDeps: [timeRange] })
    const getCurrentPageContent = (content: JSX.Element) => {
        return <BasicLayout name='生产管理'>
            <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} size={0.85} />
            {/* <TimeBar2 /> */}
            {content}
        </BasicLayout>
    }
    if (loading) return getCurrentPageContent(<Loading1 />)
    if (error) return getCurrentPageContent(<Error />)
    let [statisticValues, capacityc, count, shoeTypeCount]: any = data;
    const miniCards = [
        { title: '产量', value: statisticValues.count, unit: '双', icon: 'output.png' },
        { title: '产能', value: null, unit: statisticValues.capacityUnit, icon: 'capacity.png' },
        { title: '节拍', value: statisticValues.taktBySecondsPerPair, unit: '秒/双', icon: 'rhythm.png' },
        { title: '款式', value: statisticValues.shoeTypeCount, unit: '款', icon: 'shoeShape.png' },
    ]
    return getCurrentPageContent(<React.Fragment>
        <EasyGridLayout width={355} height={175} rows={2} columns={2} padding={[0, 0, 0, 0]} center gap={10}>
            {
                miniCards.map(item => <MiniCard value={item.value} unit={item.unit} icon={item.icon} title={item.title} />)
            }
        </EasyGridLayout>
        <LineLayout justify='around' padding={[10, 0, 15, 0]} gap={10} direction='column'>
            <Card height={250} title='产量(双)' icon='line.png'>
                <Charts.BasicLineChart width={355} height={200}
                    dataX={handleDataForLineChart((timeRange as any).timeRangeType, count).xAxis}
                    dataY={handleDataForLineChart((timeRange as any).timeRangeType, count).yAxis.map((item: any) => {
                        return item === undefined || item === null ? item : _.round(item);
                    })}
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null}
                />
            </Card>
            {/* <Card height={250} title='产能(双/小时)' icon='line.png'>
                <Charts.BasicLineChart width={355} height={200}
                    dataX={handleDataForLineChart((timeRange as any).timeRangeType, capacityc).xAxis}
                    dataY={handleDataForLineChart((timeRange as any).timeRangeType, capacityc).yAxis.map((item: any) => {
                        return item === undefined || item === null ? item : _.round(item);
                    })}
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null}
                />
            </Card> */}
            <Card height={250} title='款式' icon='model.png'>
                <Charts.TreeMapChart width={355} height={200}
                    data={handleDataForTreemap(shoeTypeCount)}
                />
            </Card>
        </LineLayout>
        <ExportButton api='/Export/ExportProductionReport' title={'生产报表'} />

    </React.Fragment>)
}
export { Production }

