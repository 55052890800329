import React, { cloneElement } from 'react'

import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { formatLineChartXAxis } from './time'
import _, { round } from 'lodash'
let loading = React.createElement(Loading, null, null)
let error = React.createElement(Error, null, null)

/**
 * 处理简单的折线图数据
 * @param timeRangeType 
 * @param data 
 * @returns 
 */
const handleDataForLineChart = (timeRangeType: number, data: Array<any>) => {
    return {
        yAxis: data.map(item => item.count),
        xAxis: formatLineChartXAxis(timeRangeType, data)
    }
}

const handleDataForEasyChart = (data: Array<any>, timeRangeType?: number) => {
    let result = {
        dataX: [],
        dataY: []
    }
    if (!Array.isArray(data)) return result
    if (data.length === 0) return result
    result.dataX = formatLineChartXAxis(timeRangeType, data)
    result.dataY = data.map(item => round(item.count))
    return result
}

const handleDataForOvenLineChart = (timeRangeType: number, data: Array<any>, propsLegend?: any) => {
    const legend = propsLegend || ['烘箱1', '烘箱2', '烘箱3', '烘箱4', '烘箱5', '烘箱6',]
    let result: any = { xAxis: [], series: [] }
    if (data.length === 0) return result
    result.xAxis = formatLineChartXAxis(timeRangeType, data[0].values)
    result.series = data.map((item, idx) => { return { name: legend[idx], data: item.values.map((childItem: any) => _.round(childItem.count, 1)) } })
    // console.log('result', result);
    return result
}

const getAllLineChartDataY = (data: Array<any>) => {
    return data.map(item => {
        return {
            name: `产线${item.lineCode}`,
            data: item.values.map((child: any) => child.count)
        }
    })
}
const handleDataForTreemap = (data: any) => {
    return data.map((item: any) => {
        return {
            value: item.count,
            name: item.shoeTypeNameForHuman
        }
    })
}


/**
 * 获取url里的参数 返回一个对象
 * @returns 
 */
const searchParams = () => {
    let result: any = {}
    let search: string = window.location.href.split('?')[1]
    if (!search) return result
    let params: Array<any> = search.split('&')
    params.forEach(item => {
        result[item.split('=')[0]] = item.split('=')[1]
    })
    // console.log('result', result);
    return result
}



const handleRequet = (contentELement: JSX.Element): [Function, JSX.Element] => {
    let defaultElement: JSX.Element = loading
    return [(request?: any) => {
        if (!request.loading) {
            if (request.error) { return error }
            let data: any = {}
            data = request.data

            // if (Array.isArray(request.data)) {
            //     // request.data.forEach((item: any) => {
            //     //     data = { ...data, ...item }
            //     // })
            //     data = request.data
            // } else {
            //     data = request.data
            // }


            defaultElement = cloneElement(contentELement, { data })
            // console.log(defaultElement)
            return defaultElement
        }
        return defaultElement
    }, loading]
}

const handleArrayToObject = (data: any, target: string) => {
    let result: any = {}
    data.forEach((item: any) => {
        result[item[target]] = item;
    });
    return result
}


export { searchParams, handleRequet, handleDataForLineChart, handleDataForEasyChart, handleDataForTreemap, handleDataForOvenLineChart, getAllLineChartDataY, handleArrayToObject }
