
import { BasicLayout } from '@/layout';
import { store } from '@/store';
import { searchParams } from '@/utils';
import { useMount } from 'ahooks';
import { useState } from 'react';
import EZUIKit from 'ezuikit-js';
import './index.scss'
//需要一个单独界面
//播放视频用
//进来加载成功后马上转为全
export const LivePlayer = () => {
  const [name, setName] = useState('')
  useMount(() => {
    const cameraId = Number(searchParams().cameraId)
    const camera = store.getState().cameras.find((item: any) => item.id === cameraId)
    setName(camera.name)
    var player = new EZUIKit.EZUIKitPlayer({
      height: '240',
      width: '355',
      id: 'live-player-container',
      accessToken: store.getState().ysyToken,
      url: camera.yS7UrlFluency,
      footer: ['talk', 'broadcast', 'hd', 'fullScreen'],
      header: ['capturePicture', 'save', 'zoom'],
    })
  })

  return <BasicLayout name={name}>
    <div id='live-player-container'>222</div>
  </BasicLayout>
}
