import React, { useState } from 'react';
import './index.scss';
import { TimeBar } from '@/components/bar';
import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout'
import { Card } from '@/components/card';
import { DigitalBlock } from '@/components/digitalBlock';
import { Icon } from '@/components/icon';
import { Circle } from '@/components/circle';
import * as Charts from '@/components/charts';
import { getTimeRangeByUrl } from '@/utils/time';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import _ from 'lodash';
import { searchParams, handleDataForLineChart } from '@/utils';
import { ExportButton } from '@/components/exportButton';
import FormatterType from '@/components/charts/formatterType';
const lineCode = searchParams().lineCode;

const Equipment = () => {
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Equipment.getCurrentElectricityPowerInW(lineCode),
            API.Equipment.getlectricityValues(lineCode, timeRange),
            API.Equipment.getUtilizationHours(lineCode, timeRange),
            API.Equipment.getUtilizationRateByTimeRange(lineCode, timeRange),
            API.Equipment.getElectricityQuantityInKWHByTimeRange(lineCode, timeRange)
        ])
    }, { refreshDeps: [timeRange] })

    if (loading) return <BasicLayout name='能源管理'>
        <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} size={0.85} height={35} />
        <Loading />
    </BasicLayout>
    if (error) return <BasicLayout name='能源管理'>
        <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} size={0.85} height={35} />
        <Error size={0.5} />
    </BasicLayout>

    let [powerInW, elctricityValues, utilizationHours, utilizationRate, electricityQuantity]: any = data;

    return (
        <BasicLayout name='能源管理'>
            <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} size={0.85} height={35} />
            <Card customBar={<React.Fragment />} >
                <LineLayout justify='start' padding={[0, 0, 0, 10]} height={70} gap={10}>
                    <Circle>
                        <Icon iconName='bootTime.png' size={1.2} />
                    </Circle>
                    <DigitalBlock value={_.round(utilizationHours.usedHours, 1)} name='开机时长' valueType='time' size={0.85} spacing={90} />
                </LineLayout>
            </Card>
            <Card customBar={<React.Fragment />} margin={[10, 0, 0, 0]}>
                <LineLayout justify='start' padding={[0, 0, 0, 10]} height={70} gap={10}>
                    <Circle>
                        <Icon iconName='standbyTime.png' size={1.2} />
                    </Circle>
                    <DigitalBlock value={_.round(utilizationHours.waitingHours,2)} name='待机时长' valueType='time' size={0.85} spacing={90} color='#FFA500' />
                </LineLayout>
            </Card>
            <EasyGridLayout width={355} height={170} rows={2} columns={2} padding={[10, 0, 0, 0]} center gap={10}>
                <Card customBar={<React.Fragment />}>
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='power.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={powerInW / 1000} name='当前功率' unit='kW' size={0.85} spacing={10} />
                    </LineLayout>
                </Card>
                <Card customBar={<React.Fragment />}>
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='direct.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={utilizationHours.utilizationRate * 100} name='稼动率' unit='%' size={0.85} spacing={10} />
                    </LineLayout>
                </Card>
                <Card customBar={<React.Fragment />}>
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='energyConsumption.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={elctricityValues.quantityInKWH} name='能耗' unit='度' size={0.85} spacing={10} color='#FFA500' />
                    </LineLayout>
                </Card>
                <Card customBar={<React.Fragment />}>
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='utilizationRate.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={elctricityValues.energyUtilizationInPairPerKWH} name='能源利用率' unit='双/度' size={0.85} spacing={10} retentionDigit={1} />
                    </LineLayout>
                </Card>
            </EasyGridLayout>
            <LineLayout justify='around' margin={[10, 0, 0, 0]} padding={[15, 0, 15, 0]} gap={10} direction='column'  >
                <Card width={350} height={250} title='稼动率(%)' icon='line.png'>
                    <Charts.BasicLineChart width={340} height={200}
                        dataX={handleDataForLineChart((timeRange as any).timeRangeType, utilizationRate).xAxis}
                        dataY={handleDataForLineChart((timeRange as any).timeRangeType, utilizationRate).yAxis.map((item: any) => {
                            return item === undefined || item === null ? item : _.round(item * 100, 1);
                        })}
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null}
                    />
                </Card>
                <Card width={350} height={250} title='能耗(度)' icon='line.png'>
                    <Charts.BasicLineChart width={340} height={200}
                        dataX={handleDataForLineChart((timeRange as any).timeRangeType, electricityQuantity).xAxis}
                        dataY={handleDataForLineChart((timeRange as any).timeRangeType, electricityQuantity).yAxis}
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null}
                    />
                </Card>
            </LineLayout>
            <ExportButton api='/Export/ExportEquipmentUtilizationReport' title='能源报表' />

        </BasicLayout>
    )
}

export { Equipment }