import { Card } from "@/components/card"
import { store, setCurrentRobotStatus } from "@/store"
import { LineLayout } from "@/layout"
import React, { useState } from "react"
import { Equipment } from "@/api/request"
import { searchParams } from "@/utils"
import useRequest from "@ahooksjs/use-request"
import { Loading1 } from "@/components/loading"
import { useMount } from "ahooks"
import './index.scss'
import { Icon } from "@/components/icon"
import { PageBar } from "@/components/bar"
export const WorkStationNavigation = () => {
    const [lineCode] = useState(searchParams().lineCode)
    const [robotStatus, setRobotStatus] = useState(store.getState().currentRobotStatus)
    const [loading, setLoading] = useState(true)
    const pageName = store.getState().projectInfo.name;
    useMount(() => {
        // setLoading(false)
        if (robotStatus !== null && robotStatus.length !== 0) {
            setLoading(false)
        }
    })
    const { data } = useRequest(() => Equipment.getRobotCurrentStatuses(lineCode), {
        onSuccess: () => {
            const robots = store.getState().robots
            const currentRobotStatus = robots.map((item: any) => {
                return {
                    ...item,
                    params: data.find((status: any) => status.workStationCode === item.workStationCode)
                }
            })
            setRobotStatus(currentRobotStatus)
            setCurrentRobotStatus(currentRobotStatus)
            setLoading(false)
        },
        pollingInterval: 1000 * 5
    })

    const jumpTo = (item: any) => {
        return `/line/workStation?lineCode=${lineCode}&workStationCode=${item.workStationCode}`

    }
    return <React.Fragment>
        <PageBar title={'加工站'} />
        <React.Fragment>
            {
                loading ? <Loading1 /> :
                    <React.Fragment>
                        <LineLayout justify='between' padding={[20, 0, 10, 20]}>
                            <div style={{ color: '#fff', fontSize: '20px' }}>{store.getState().robots.length}个机器人加工站</div>
                        </LineLayout>
                        {
                            robotStatus.map((item: any) => {
                                return <Card title={item.name} jumpTo={jumpTo(item)} height='200' margin={[0, 0, 10, 15]} width={345}>
                                    <LineLayout justify='start' direction='column' >

                                        <LineLayout justify='start' alignItems='baseLine' width={330}>
                                            <div className='shoe-type-name'>
                                                {item.params && item.params.shoeTypeNameForHuman ? item.params.shoeTypeNameForHuman : '--'}
                                            </div>
                                            <div>
                                                <span className='shoe-size'>
                                                    {item.params ? item.params.shoeSizeForHuman : '--'}
                                                </span>
                                                <span className='shoe-right'>
                                                    {item.params ?
                                                        <span>{item.params.shoeIsRight ? '左' : '右'}</span> : '--'}
                                                </span>
                                            </div>
                                        </LineLayout>

                                        <LineLayout justify='between' width={330} padding={[40, 0, 0, 0]}>
                                            <div className='robot-status-item'>
                                                <Icon iconName='sk11.png' size={1.8} />
                                                <p>{item.params ? item.params.shoesCount : '--'}</p>
                                            </div>
                                            <div className='robot-status-item'>
                                                <Icon iconName='sk9.png' size={1.8} />
                                                <p>{item.params ? item.params.speedRatio : '--'}</p>

                                            </div>
                                            <div className='robot-status-item'>
                                                <Icon iconName='sk6.png' size={1.8} />
                                                <p>{item.params ? '温度正常' : '--'}</p>
                                            </div>
                                            <div className='robot-status-item'>
                                                <Icon iconName='sk7.png' size={1.8} />
                                                <p>{item.params ? '风扇正常' : '--'}</p>
                                            </div>
                                        </LineLayout>
                                    </LineLayout>

                                </Card>
                            })
                        }
                    </React.Fragment>
            }
        </React.Fragment>
    </React.Fragment >
}
