
import { CardBar } from '@/components/bar';
import { BasicLayout, CenterLayout, EasyGridLayout } from '@/layout';
import * as Thumbmail from '@/components/thumbnail'
import { useRequest } from 'ahooks';
import * as API from '@/api/request'
import { Loading1 } from '@/components/loading'
import { Error } from '@/components/error';
import { Card } from '@/components/card';
import { store } from '@/store';
import {SFactoryHome} from '@/pages/workShop/home/sFactory'
import {XFactoryHome} from '@/pages/workShop/home/xFactory'

export const Home = () => {
  const shortCode = store.getState().projectInfo.shortCode
   if(shortCode ==="S1") {
     return <SFactoryHome></SFactoryHome>
   }
   if(shortCode ==="X" ||  shortCode ==="X1") {
     return <XFactoryHome></XFactoryHome>
  }
  return null
}
    