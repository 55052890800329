import React, { useState } from 'react';
import './index.scss';
import { TimeBar, RegionBar, CardBar } from '@/components/bar';
import { BasicLayout, LineLayout } from '@/layout'
import { Card } from '@/components/card';
import { DigitalBlock } from '@/components/digitalBlock';
import * as Charts from '@/components/charts';
import { BasicUnderLine } from '@/components/underLine';
import { searchParams, handleDataForOvenLineChart, handleDataForLineChart } from '@/utils';
import { getTimeRangeByUrl } from '@/utils/time';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import FormatterType from '@/components/charts/formatterType';
import { store } from '@/store';
const lineCode = searchParams().lineCode || null;
const shortCode = store.getState().projectInfo.shortCode;
const Oven = () => {
    const { loading, data, error } = useRequest(() => { return API.Equipment.getOvensCurrentParameters(lineCode) })
    if (loading) {
        return <BasicLayout name='烘箱'>
            <Loading></Loading>
        </BasicLayout>
    }

    if (error) {
        return <BasicLayout name='烘箱'>
            <Loading></Loading>
        </BasicLayout>
    }
    const ovensData = store.getState().ovenOrRefrigerators;
    const ovesnWithTemperatureInC = ovensData.map((oven: any) => {
        let dataItme = data.find((item: any) => oven.code === item.ovenCode)
        return {
            ...oven,
            temperatureInC: dataItme ? dataItme.temperatureInC : null,
            powerInW: dataItme ? dataItme.powerInW : null
        }
    })

    return <BasicLayout name='烘箱'>
        <Card customBar={<React.Fragment />} height={40.5 * ovesnWithTemperatureInC.filter((item: any) => item.lineCode === lineCode)} margin={[0, 0, 20, 0]}>
            <LineLayout justify='start' direction='column'>
                {ovesnWithTemperatureInC.filter((item: any) => item.lineCode === lineCode).map((item: any, index: number) => {
                    return <LineLayout justify='between' width={345} key={index}>
                        <CardBar title={item.name} icon={`${index === 3 ? 'heatSetting' : index === 7 ? 'coldSetting' : 'oven'}.png`} hideForward size={0.8} width={130} />
                        {index === ovesnWithTemperatureInC.length - 1 ? '' : <BasicUnderLine />}
                        {
                            item ? <DigitalBlock value={item.temperatureInC} size={0.8} unit='℃' width={85} textAlign='right'
                                color={item.name === '冷定型' ? '#00e2ff' : item.name === '热定型' ? '#ff2800' : 'orange'}
                            /> : <p style={{ fontWeight: 'bold' }}>--</p>
                        }

                        {/* {
                            item ? <DigitalBlock value={item.powerInW / 1000} size={0.8} unit='kW1' width={85} textAlign='right' /> : <p style={{ fontWeight: 'bold' }}>--</p>
                        } */}
                    </LineLayout>
                })}
            </LineLayout>
        </Card>
    </BasicLayout>
}


export { Oven }