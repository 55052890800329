
import React from 'react'
import { CardBar } from '@/components/bar'
import classNames from 'classnames'
import { LineLayout } from '@/layout'
import { Circle } from '@/components/circle'
import { Icon } from '@/components/icon'
import { DigitalBlock } from '@/components/digitalBlock'
import { useHistory } from 'react-router-dom'
import './index.scss'

interface CardProps {
  children?: JSX.Element | Array<JSX.Element>,
  background?: string,
  radius?: number,
  title?: string,
  icon?: string,
  customBar?: JSX.Element,
  jumpTo?: string,
  margin?: Array<number>,
  padding?: Array<number>,
  hideBorder?: Boolean,
  center?: 'vertical' | 'horizontal' | 'all',
  width?: number | string,
  height?: number | string,
  minHeight?:number | string
}
//#151515


const transStyle = (style: CardProps): string => {
  let styleStr: string = ''
  if (Array.isArray(style)) {
    style.forEach((item) => {
      styleStr = styleStr + ' ' + item + 'px'
    })
    return styleStr
  }
  return style + 'px'
}



// 卡片内容容器
const Card = (props: any) => {



  const history = useHistory()

  let cardStyle = {
    width: props.width ? props.width + 'px' : '100%',
    height: props.height ? props.height + 'px' : '100%',
    background: props.background ? props.background : `url(${require('../../assets/images/cardBackGround.png').default}) repeat`,
    borderRadius: props.radius ? props.radius + 'px' : '6px',
    margin: transStyle(props.margin),
    minHeight:props.minHeight?props.minHeight+'px':null
  }
  let cardContentStyle = {
    justifyContent: (props.center === 'horizontal' || props.center === 'all') ? 'center' : 'flex-start',
    alignItems: (props.center === 'vertical' || props.center === 'all') ? 'center' : 'flex-start',
    padding: transStyle(props.padding)
  }


  const jumpTo = (jumpTo: string) => {
    history.push(jumpTo);
  }

  return (
    <div
      onClick={() => {
        if (props.jumpTo === undefined || props.jumpTo === null) {
          return 0;
        }
        jumpTo(props.jumpTo);
        document.documentElement.scrollTop = 0;
      }}
      className={classNames({
        'card': true,
        'card-border': !props.hideBorder
      })} style={cardStyle}>
      {
        !!props.customBar ? props.customBar : <CardBar title={props.title || ''} icon={props.icon || ''} hideForward={!props.jumpTo} size={0.8} />
      }
      <div className='card-content' style={cardContentStyle}>
        {props.children}
      </div>
    </div>
  )
}

interface MiniCardProps extends CardProps {
  value: number,
  icon: string,
  unit?: string,
  size?: 'long' | 'short'
}

const MiniCard = (props: MiniCardProps) => {
  return <Card customBar={<React.Fragment />} center='vertical' width={props.width} height={props.height} background={props.background} margin={props.margin}>
    <LineLayout justify='start' padding={[0, 0, 0, 10]} gap={10} width={props.width}  >
      <Circle>
        <Icon iconName={props.icon} size={1.2} />
      </Circle>
      <DigitalBlock
        type={props.size === 'long' ? 'transverse' : 'nameStacking'}
        value={props.value}
        name={props.title}
        unit={props.unit || ''}
        size={0.85}
        spacing={props.size === 'long' ? 180 : 10} />
    </LineLayout>
  </Card>
}

export { Card, MiniCard }