import { BasicLayout, LineLayout } from '@/layout'
import { Image } from '@/components/image'
import { Button } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
export const NotFound = () => {
    const history = useHistory()
    const clickJumpTo = () => {
        return history.push('/home')
    }
    return <BasicLayout name='页面不存在' >
        <LineLayout justify='start' direction='column' margin={[50, 0, 0, 0]}>
            <Image url='404.png' width={280} />
            <Button type='primary' style={{ width: '280px' }} onClick={clickJumpTo}>返回首页</Button>
        </LineLayout>
    </BasicLayout >
}