import { BarChart } from "@/components/charts";
import { DigitalBlock } from "../digitalBlock";
import { LineLayout } from '@/layout'
import { useRequest } from "ahooks";
import * as API from '@/api/request'
import { handleDataForEasyChart, searchParams } from "@/utils";
import { Loading2 } from "@/components/loading";
import { Error } from "../error";
import { Fragment } from "react";
const lineCode = searchParams().lineCode || null
export const Production = () => {
    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Production.getCountByLast15Days(lineCode),
            API.Production.getTotalCount(lineCode)
        ])
    })
    if (loading) return <Loading2 />
    if (error) return  <Error size={0.5} />
    const [countBy15days, totalCount] = data
    return (

        <Fragment>
            <LineLayout justify='between' direction='column' gap={15} margin={[15, 0, 0, 0]}>
                <BarChart
                    {...handleDataForEasyChart(countBy15days, 2)}
                    width={120}
                    height={100}
                />
                <DigitalBlock value={totalCount} unit='双' name='累积' spacing={1} size={0.8} />
            </LineLayout>
        </Fragment>
    );
};
