import { BasicLayout, EasyGridLayout, LineLayout } from "@/layout"
import { MiniCard, Card } from "@/components/card"
import { TimeBar } from "@/components/bar"
import { BasicLineChart, TreeMapChart } from '@/components/charts'
import { useRequest } from "ahooks"
import ProductionAPI from '@/api/modules/production'
import { useState } from "react"
import { handleDataForEasyChart } from "@/utils"
import Loading from "@/components/loading/loading"
import { Error } from '@/components/error'
import { getTimeRangeByUrl } from "@/utils/time"
import FormatterType from '@/components/charts/formatterType';
const pageName = '车间生产'
export const Production = () => {
    //获取页面数据
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl());
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            ProductionAPI.getStatisticValues(null, timeRange),
            ProductionAPI.getCapacityInPairPerHourByTimeRange(null, timeRange),
            ProductionAPI.getCountByTimeRange(null, timeRange),
            ProductionAPI.getCountByShoeType(null, timeRange),
        ])
    }, { refreshDeps: [timeRange] })
    if (loading)
        return <BasicLayout name={pageName}>
            <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} size={0.85} height={35} />
            <Loading />
        </BasicLayout>

    if (error)
        return <BasicLayout name={pageName}>
            <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} size={0.85} height={35} />
            <Error />
        </BasicLayout>
    const [statisticValues, capacityByTimeRange, countByTimeRange, countByShoeType]: any = data
    const miniCards = [
        { title: '产量', value: statisticValues.count, icon: 'output.png', unit: '双' },
        { title: '产能', value: statisticValues.capacity, icon: 'capacity.png', unit: '双/小时' },
        { title: '节拍', value: statisticValues.shoeTypeCount, icon: 'rhythm.png', unit: '秒/双' },
        { title: '款式', value: statisticValues.taktBySecondsPerPair, icon: 'shoeShape.png', unit: '款' }
    ]

    console.log(countByShoeType)
    return <BasicLayout name={pageName}>
        <TimeBar size={0.8} change={(timeRange: any) => { setTimeRange(timeRange) }} />
        <EasyGridLayout width={350} height={180} rows={2} columns={2} gap={10}>
            {
                miniCards.map(item => <MiniCard icon={item.icon} title={item.title} value={item.value} unit={item.unit} />)
            }
        </EasyGridLayout>

        <LineLayout justify='start' direction='column' margin={[10, 0, 0, 0]} >
            <Card title='车间产量(双)' icon='output.png' height={250}>
                <BasicLineChart
                    dataX={handleDataForEasyChart(countByTimeRange, (timeRange as TimeRange).timeRangeType).dataX}
                    dataY={handleDataForEasyChart(countByTimeRange, (timeRange as TimeRange).timeRangeType).dataY}
                    width={360}
                    height={210}
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null} />
            </Card>)

            <Card title='车间产能(双/小时)' icon='output.png' height={250}>
                <BasicLineChart
                    dataX={handleDataForEasyChart(capacityByTimeRange, (timeRange as TimeRange).timeRangeType).dataX}
                    dataY={handleDataForEasyChart(capacityByTimeRange, (timeRange as TimeRange).timeRangeType).dataY}
                    width={360}
                    height={210}
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null} />
            </Card>

            <Card height={250} title='款式' icon='model.png'>
                <TreeMapChart width={355} height={200} data={countByShoeType.map((item: any) => { return { name: item.shoeTypeNameForHuman, value: item.count } })} />
            </Card>

        </LineLayout>

    </BasicLayout>
}