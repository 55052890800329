import { store } from '@/store'
import { Route, Redirect } from 'react-router-dom'
interface PrivateRouteProps {
    path: string,
    content: any,
    exact: boolean,
    isLine?: boolean,
    key: number
}

const getParamsFromSearch = (search: string) => {
    let result: any = {}
    search = search.split('?')[1]
    if (!search) return result
    let params: Array<any> = search.split('&')
    params.forEach(item => {
        result[item.split('=')[0]] = item.split('=')[1]
    })
    return result
}
//判断是否登录
const isLogin =()=>{
   let password =  localStorage.getItem("ZsTekWebKanbanKey")
   return password ==="zs123"
}
export const PrivateRoute = (props: PrivateRouteProps) => {
    const installLineCodes = store.getState().installLineCodes
    //跳转页面的时候定位到页面的头部
    if (!props.path) {
        return <Redirect to='/notFound' />
    }
  
    if (props.isLine) {
        //@ts-ignore
        if (!installLineCodes.includes(getParamsFromSearch(props.location.search).lineCode)) {
            return <Redirect to='/pageError' />
        }
    }
    window.scrollTo(0, 0)
    return <Route exact={props.exact} path={props.path} key={props.key}>
     {
      props.content
     }

    </Route>
}