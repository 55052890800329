import { Image } from '@/components/image'
import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout'
import { Card } from '@/components/card'
import { store } from '@/store'
import { Line } from '@/components/thumbnail'
import { Button } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import './index.scss'
export const PageError = () => {
    const history = useHistory()
    const installLineCodes = store.getState().installLineCodes
    const clickJump = () => {
        history.push('/home')
    }

    return <BasicLayout name={store.getState().projectInfo.name} >
        <LineLayout justify='start' direction='column' margin={[20, 0, 70, 0]}>
            <Image url='error.png' width={200} />
            <p className='error-tip'>产线不存在!</p>
            <Button onClick={clickJump} type='primary' style={{ width: '280px' }}>返回首页</Button>

        </LineLayout>

        <div className='block-title'>全部产线</div>
        <EasyGridLayout rows={3} columns={2} width={350} height={480} gap={10} >
            {
                installLineCodes.map((item: any) => {
                    return <Card title={`产线${item}`} jumpTo={`/line?lineCode=${item}`}  >
                        <Line lineCode={item} />
                    </Card>
                })
            }
        </EasyGridLayout>
    </BasicLayout >
}