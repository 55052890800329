import React, { useEffect } from 'react';
import * as echarts from 'echarts';

interface BasicLineProps {
    dataX: Array<number | string>,
    dataY: Array<any>,
    width: number,
    height: number,
    formatter?: Function,
    hideYAxis?: boolean
}

/**
 * 图表-单折线图
 * @datax Array<number | string>, x轴数据
 * @datay Array<number>, y轴数据
 * @width number, 容器宽度
 * @height numebr, 容器高度
 * @formatter boolean, 针对今日展示特定提示框
 */

const BasicLineChart = (props: BasicLineProps) => {

    const id = new Date().getTime().toString() + Math.random();

    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: props.formatter
                },
                grid: {
                    left: '3%',
                    right: '3%',
                    top: '5%',
                    bottom: '2%',
                    containLabel: true,
                },
                dataZoom: {
                    type: 'inside'
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    axisLabel: {
                        color: '#f8f8f8',
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    data: props.dataX
                },
                yAxis: {
                    type: 'value',
                    show: !props.hideYAxis,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    axisLabel: {
                        color: '#f8f8f8',
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                },
                series: [{
                    data: props.dataY,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        color: '#4992ff'
                    },
                    itemStyle: {
                        color: '#4992ff'
                    },
                    label: {
                        show: true,
                        position: 'bottom',
                        color: '#f8f8f8',
                        formatter: function (params) {
                            let maxIndex: string | number = 0;
                            for (let i in props.dataY) {
                                maxIndex = props.dataY[i] > props.dataY[maxIndex] ? i : maxIndex;
                            }
                            // eslint-disable-next-line
                            return params.dataIndex == maxIndex ? params.data : '';
                        }
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: '#4992ff' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#000' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        opacity: 0.65,
                    }
                }]
            };
            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} ></ div>
    )
}

interface StackedLineProps {
    legend: Array<string>,
    dataX: Array<number | string>,
    dataY: Array<ObjectStackedLineDatay>,
    width: number,
    height: number,
}

interface ObjectStackedLineDatay {
    name: string,
    data: Array<number>,
}

interface ObjectStackedLineSeriesData {
    name: string,
    data: Array<number>,
    type: string,
    smooth: boolean,
    label: ObjectStackedLineLabel
}

interface ObjectStackedLineLabel {
    show: boolean,
    position: string,
    color: string,
    formatter: Function,
}

/**
 * 图表-多折线图
 * @legend Array<string>, 图例名称
 * @datax Array<number | string>, x轴数据
 * @datay Array<{name: string, data: Array<number>}>, y轴数据, name表示折线名称, data表示折线数据
 * @width number, 容器宽度
 * @height numebr, 容器高度
 */

const StackedLineChart = (props: StackedLineProps) => {

    const id = new Date().getTime().toString() + Math.random();

    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);
            let option;
            let seriesData: Array<ObjectStackedLineSeriesData> = [];
            props.dataY.forEach((item: ObjectStackedLineDatay) => {
                seriesData.push({
                    name: item.name,
                    data: item.data,
                    type: 'line',
                    smooth: true,
                    label: {
                        show: true,
                        position: 'top',
                        color: '#f8f8f8',
                        formatter: function (params) {
                            let maxIndex: string | number = 0;
                            for (let i in item.data) {
                                maxIndex = item.data[i] > item.data[maxIndex] ? i : maxIndex;
                            }
                            // eslint-disable-next-line
                            return params.dataIndex == maxIndex ? params.data : '';
                        }
                    }
                })
            })

            option = {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: props.legend,
                    orient: 'horizontal',
                    x: 'center',
                    y: 'bottom',
                    right: 0,
                    align: 'right',
                    padding: [0, 0, 2, 0],
                    textStyle: {
                        color: '#c5c5c5',
                        fontSize: 8,
                    },
                },
                grid: {
                    left: '0%',
                    right: '3%',
                    top: '10%',
                    bottom: '20%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    axisLabel: {
                        color: '#f8f8f8',
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    data: props.dataX,
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                    axisLabel: {
                        color: '#f8f8f8',
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#393e46',
                        },
                    },
                },
                series: seriesData,
            };
            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} ></ div>
    )
}

export { BasicLineChart, StackedLineChart }