
import { Icon } from '../icon'
import './index.scss'
interface EmptyProps {
    value?: string
}
export const Empty = (props: EmptyProps) => {
    return <div className='empty-content'>
        <Icon iconName='empty.png' size={4} />
        <p>{props.value}</p>

    </div>
}