import React from 'react'
import './index.scss'

interface ConspicuousNumber {
    value: number
}
export const ConspicuousNumber = (props: ConspicuousNumber) => {
    const toThousands = (num: number) => {
        let result = ''
        let counter = 0;
        let numStr = (num || 0).toString();
        for (var i = numStr.length - 1; i >= 0; i--) {
            counter++;
            result = numStr.charAt(i) + result;
            if (!(counter % 3) && i != 0) { result = ',' + result; }
        }
        return result;
    }


    return <div className='conspicuous-number'>
        <div className='conspicuous-number-value'>{toThousands(props.value)}</div>
        <div className='conspicuous-number-unit'>双</div>
    </div>
}
