import React from 'react';
import './index.scss';
import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout'
import { Card } from '@/components/card';
import { DigitalBlock } from '@/components/digitalBlock';
import { Icon } from '@/components/icon';
import { Circle } from '@/components/circle';
import * as Charts from '@/components/charts';
import { searchParams } from '@/utils';
import { useRequest } from 'ahooks';
import { EnvironmentApi } from '@/api/request';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { formatLineChartXAxis } from '@/utils/time';
import _ from 'lodash';
import { ExportButton } from '@/components/exportButton';
import FormatterType from '@/components/charts/formatterType';
const lineCode = searchParams().lineCode;

const getChartData = (environmentName: string, data: Array<any>) => {
    return {
        xAxis: formatLineChartXAxis(0, data),
        yAxis: data.map(item => {
            if (!!item.values) {
                //湿度特殊处理一下
                if (environmentName === 'humidness') {
                    return _.round(item.values.humidness * 100)
                }
                return _.round(item.values[environmentName])
            }
            return null
        })
    }
}

const Environment = () => {

    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            EnvironmentApi.getCurrentEnvironmentValues(lineCode),
            EnvironmentApi.getEnvironmentValuesByTimeRange(lineCode)
        ])
    })
    if (loading) return <BasicLayout name='环境管理'> <Loading /> </BasicLayout>
    if (error) return <BasicLayout name='环境管理'> <Error /> </BasicLayout>

    let [environments, environmentsByChart]: any = data;

    return (
        <BasicLayout name='环境管理'>
            <EasyGridLayout width={355} height={175} rows={2} columns={2} padding={[0, 0, 0, 0]} center gap={10}>
                <Card customBar={<React.Fragment />} >
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='temperature.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={environments.temperatureInC} name='温度' unit='℃' size={0.85} spacing={10} />
                    </LineLayout>
                </Card>
                <Card customBar={<React.Fragment />}>
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='humidity.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={environments.humidness * 100} name='湿度' unit='%' size={0.85} spacing={10} />
                    </LineLayout>
                </Card>
                <Card customBar={<React.Fragment />}>
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='noise.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={environments.noiseIndB} name='噪音' unit='dB' size={0.85} spacing={10} />
                    </LineLayout>
                </Card>
                <Card customBar={<React.Fragment />} >
                    <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                        <Circle>
                            <Icon iconName='PM25.png' size={1.2} />
                        </Circle>
                        <DigitalBlock type='valueStacking' value={environments.pM25} name='PM2.5' unit='ug/m³' size={0.85} spacing={10} />
                    </LineLayout>
                </Card>
            </EasyGridLayout>
            <LineLayout justify='around' padding={[10, 0, 15, 0]} gap={10} direction='column'>
                <Card height={250} title='温度(℃)' icon='line.png'>
                    <Charts.BasicLineChart width={355} height={200}
                        dataX={getChartData('temperatureInC', environmentsByChart).xAxis}
                        dataY={getChartData('temperatureInC', environmentsByChart).yAxis}
                        formatter={FormatterType.TodayMoment}
                    />
                </Card>
                <Card height={250} title='湿度(%)' icon='line.png'>
                    <Charts.BasicLineChart width={355} height={200}
                        dataX={getChartData('humidness', environmentsByChart).xAxis}
                        dataY={getChartData('humidness', environmentsByChart).yAxis}
                        formatter={FormatterType.TodayMoment}
                    />
                </Card>
                <Card height={250} title='噪音(dB)' icon='line.png'>
                    <Charts.BasicLineChart width={355} height={200}
                        dataX={getChartData('noiseIndB', environmentsByChart).xAxis}
                        dataY={getChartData('noiseIndB', environmentsByChart).yAxis}
                        formatter={FormatterType.TodayMoment}
                    />
                </Card>
                <Card height={250} title='PM2.5(ug/m³)' icon='line.png'>
                    <Charts.BasicLineChart width={355} height={200}
                        dataX={getChartData('pM25', environmentsByChart).xAxis}
                        dataY={getChartData('pM25', environmentsByChart).yAxis}
                        formatter={FormatterType.TodayMoment}
                    />
                </Card>
            </LineLayout>
            <ExportButton api='/Export/ExportEnvironmentReport' title='环境报表' />
        </BasicLayout>
    )
}

export { Environment }