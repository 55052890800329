interface ImageProps {
    url: string,
    height?: number,
    width?: number,
    fit?: string,
    radius?: string,
    round?: Boolean
}
/**
 * 图片组件  
 * @param props 
 * 只实现了宽、高、url，    
 * TODO 加载状态、加载错误、填充形式、圆角、显示圆形。要用到再说
 */
              
const basePath = process.env.PUBLIC_URL + '/images/'
export const Image = (props: ImageProps) => {
    const src = basePath + props.url
    return <img src={src} width={props.width + 'px'} height={props.height + 'px'} alt='' />
}

