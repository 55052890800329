import React from 'react';
import { CardBar, RegionBar } from '@/components/bar';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { LineLayout } from '@/layout';
import { Image } from '@/components/image';
import { BasicUnderLine } from '@/components/underLine';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { searchParams, handleArrayToObject } from '@/utils';

const lineCode = searchParams().lineCode;
let content = <Loading />;
let robotCurrentStatuses: any;
let robotStatus = ['初始化', '伺服关闭', '伺服开启', '戒备状态', '紧急停止', '急停恢复', '系统失效', '未知'];

export const Robot = () => {
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Equipment.getRobotCurrentStatuses(lineCode),
        ])
    })

    if (loading) {
        return content;
    }

    if (error) {
        return <Error />
    } else {
        [robotCurrentStatuses] = data;
    }

    return <React.Fragment>
        <PageBar title={store.getState().projectInfo.name} />
        <RegionBar title='机械手' />
        <Card
            customBar={<React.Fragment />}
            width={345}
            margin={[10, 0, 10, 15]}
        >
            <LineLayout justify='start' direction='column' padding={[10, 0, 0, 0]} width={353}>
                <Image url={'iotRobot.png'} height={160} width={325} />
                {
                    store.getState().robots.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={`${index + 1}-${item.name}`} icon={''} hideForward size={0.8} width={150} />
                            {index === store.getState().robots.length - 1 ? '' : <BasicUnderLine />}
                            {
                                handleArrayToObject(robotCurrentStatuses, 'workStationCode')[item.workStationCode] !== undefined ?
                                    <CardBar
                                        title={robotStatus[handleArrayToObject(robotCurrentStatuses, 'workStationCode')[item.workStationCode].controllerState - 1]}
                                        size={0.8} width={100} icon={''} hideForward
                                        color={handleArrayToObject(robotCurrentStatuses, 'workStationCode')[item.workStationCode].controllerState === 3 ? 'rgb(5, 211, 128)'
                                            : handleArrayToObject(robotCurrentStatuses, 'workStationCode')[item.workStationCode].controllerState === 5 ||
                                                handleArrayToObject(robotCurrentStatuses, 'workStationCode')[item.workStationCode].controllerState === 7 ||
                                                handleArrayToObject(robotCurrentStatuses, 'workStationCode')[item.workStationCode].controllerState === 8 ? '#ff2800'
                                                : 'orange'
                                        }
                                        fontWeight
                                    /> :
                                    <p style={{ fontWeight: 'bold', width: '80px' }}>--</p>
                            }
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>
    </React.Fragment>
}