import { BasicLayout } from '@/layout'
import React from 'react'
import moment from 'moment'
import './index.scss'
//版本查看
export const Version = () => {
    const projectConfig = JSON.parse(localStorage.getItem('projectConfig'))
    const appBuildTime = moment(projectConfig.appBuildTime).format('YYYY-MM-DD hh:mm:ss')
    const serverCompiledVersion = projectConfig.serverCompiledVersion

    return <React.Fragment>
        <BasicLayout name='版本查看'>
            <div className='version-content'>
                <p>前端构建时间：{appBuildTime}</p>
                <p>服务端版本号：{serverCompiledVersion}</p>
            </div>
        </BasicLayout>
    </React.Fragment>
        
}
