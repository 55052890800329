import React, { useEffect } from 'react';
import * as echarts from 'echarts';
interface PieChartProps {
    data: Array<any>,
    width: number,
    height: number,
    isShow?: boolean,
}
const PieChart = (props: PieChartProps) => {
    const id = new Date().getTime().toString() + Math.random();

    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);
            let option = {
                grid: {
                    top: 0
                },

                title: {
                },
                tooltip: {
                    show: false,
                    trigger: 'item'
                },
                legend: {
                    show: false,
                    orient: 'vertical',
                    left: 'left',
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                      //  radius: ['55%', '70%'],
                        data: props.data,
                        label: {
                            color: '#fff',
                            fontSize:"17px",
                            formatter: function (params: any) {
                                return params.name+" " +params.percent.toFixed(0) + '%'
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,

                            }
                        }
                    }
                ]
            };
            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])
    return <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} />


}

export { PieChart }