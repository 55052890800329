import React, { ReactNode } from 'react';
import './index.scss';

interface Props {
    width?: number,
    height?: number,
    children?: ReactNode;
}

/**
 * 
 * @param {number} width 圆形宽度 number
 * @param {number} height 圆形高度 number
 * @param {ReactNode} children 圆形里的内容 ReactNode
 * @returns 
 */

const Circle = (props: Props) => {
    return (
        <div className='circle-container' style={{ width: `${props.width || 50}px`, height: `${props.height || 50}px` }}>
            {props.children}
        </div>
    )
}

export { Circle }