import service from '../service'
import { timeRanges } from '@/utils/time'

const Environment = {
    /** 
     * 获取环境数据
    */
    getCurrentEnvironmentValues: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Environment/GetCurrentEnvironmentValues',
            params: { lineCode: lineCode }
        })
    },

    /**
    * 根据时间获取函数环境数据,这里只需要今天的,数据按小时返回
    */
    getEnvironmentValuesByTimeRange: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Environment/GetEnvironmentValuesByTimeRange',
            params: { lineCode: lineCode, ...timeRanges.today(), timeRangeType: '0' }
        })
    }
}
export default Environment

