
import { CardBar } from '@/components/bar';
import { BasicLayout, CenterLayout, EasyGridLayout } from '@/layout';
import * as Thumbmail from '@/components/thumbnail'
import { useRequest } from 'ahooks';
import * as API from '@/api/request'
import { Loading1 } from '@/components/loading'
import { Error } from '@/components/error';
import { Card } from '@/components/card';
import { store } from '@/store';
import { ConspicuousNumber } from '@/components/conspicuousNumber'
export const SFactoryHome = () => {
    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Production.getTotalCount(),
            API.Production.getTodayCount('1'),
            API.Production.getTodayCount('2'),
            API.Production.getTodayCount('3'),
            API.Production.getTodayCount('4'),
        ])
    })
    if (loading) return <Loading1 />
    if (error) return <Error />

    //s2-1是lineCode2  s2-2是lineCode4  业务需要
    const totalCount = data[0]
    const s1TodayCount = data[1]
    const s2TodayCount = data[2]
    const s3TodayCount = data[3]
    const s4TodayCount = data[4]
    const todayCount = s1TodayCount + s2TodayCount + s3TodayCount+s4TodayCount
    const installLineCodes = store.getState().installLineCodes
    const shortCode = store.getState().projectInfo.shortCode
    const sFactorySnstallLineCodes = [{ name: "1", lineCode: "1" }, { name: "2-1", lineCode: "2" }, { name: "2-2", lineCode: "4" }, { name: "3", lineCode: "3" }]
    const topCards = [
        { title: '设备', icon: 'equipment.png', content: <Thumbmail.Equipment /> },
        { title: '环境', icon: 'env.png', content: <Thumbmail.Environment />, jumpTo: '/workShop/environment' }
    ]
    const pageName = "ZS-TEK S智能工厂"
    return <BasicLayout name={pageName}>
        <Card title='累计产量' height={100} icon='production.png'>
            {<ConspicuousNumber value={totalCount} />}
        </Card>

        <Card title='今日产量' height={100} margin={[10, 0, 10, 0]} icon='production.png'>
            {<ConspicuousNumber value={todayCount} />}
        </Card>
        <Card title='产线产量' height={350} margin={[10, 0, 10, 0]} icon='production.png'>
            <Thumbmail.TodayCountPieChart data={[{ name: 'S1', value: s1TodayCount }, { name: 'S2-1', value: s2TodayCount }, { name: 'S2-2', value: s4TodayCount }, { name: 'S3', value: s3TodayCount }]} />
        </Card>

        <Card height='250' title='最近15天产量（双）' icon='production.png' jumpTo='/workShop/production' margin={[10, 0, 10, 0]}>
            <Thumbmail.Production4 />
        </Card>

        <EasyGridLayout rows={1} columns={2} width={350} height={220} gap={10} >
            {
                topCards.map(item => {
                    return <Card title={item.title} icon={item.icon} jumpTo={item.jumpTo} center='horizontal' children={item.content} />
                })
            }
        </EasyGridLayout>
        <CardBar title='产线' icon='' hideForward />
        <EasyGridLayout rows={3} columns={2} width={350} height={480} gap={10} >
            {
                shortCode === "S1" ? sFactorySnstallLineCodes.map((item: any) => {
                    return <Card title={`S${item.name}`} jumpTo={`/line?lineCode=${item.lineCode}`}  >
                        <Thumbmail.Line lineCode={item.lineCode} />
                    </Card>
                }) :
                    installLineCodes.map((item: any) => {
                        return <Card title={`S${item}`} jumpTo={`/line?lineCode=${item}`}  >
                            <Thumbmail.Line lineCode={item} />
                        </Card>
                    })
            }

        </EasyGridLayout>
    </BasicLayout>
}