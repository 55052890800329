import React, { useEffect } from "react";
import EZUIKit from 'ezuikit-js';

interface Props {
    width: number,
    height: number,
    url: string,
    token: string,
}

/**
 * 视频播放
 * @width number, 容器宽度
 * @height numebr, 容器高度
 * @url string 视频监控地址
 * @token string 萤石云平台AccessToken
 */

const Uikit = (props: Props) => {

    let uikitStyle = {
        width: props.width ? props.width + 'px' : '100%',
        height: props.height ? props.height + 'px' : '100%',
    }

    const id = new Date().getTime().toString();

    useEffect(() => {

        let player = new EZUIKit.EZUIKitPlayer({
            id: id, // 视频容器ID
            accessToken: props.token,
            url: props.url,
        });
        player.play();
        // eslint-disable-next-line
    }, [])



    return (
        <div id={id} style={uikitStyle}></div>
    )
}

export { Uikit }
