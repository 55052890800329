import React, { useState } from 'react';
import { store } from '@/store';
import { Image } from '@/components/image';
import { Error } from '@/components/error';
import { Loading2 } from '@/components/loading';
import { DigitalBlock } from '@/components/digitalBlock';
import { useRequest } from 'ahooks';
import * as API from '@/api/request';
import { searchParams } from '@/utils';
import { round } from 'lodash';
export const Environment2 = () => {
    const { loading, data, error } = useRequest(() => API.Environment.getCurrentEnvironmentValues(searchParams().lineCode || null))
    if (loading) return <Loading2 />
    if (error) return <Error size={0.5} />
    let digitals = [
        { name: '温度', unit: '℃', value: round(data.temperatureInC) },
        { name: '湿度', unit: '%', value: round(data.humidness * 100) },
        { name: '噪音', unit: 'dB', value: round(data.noiseIndB) },
        { name: 'pM2.5', unit: 'ug/m3', value: round(data.pM25) }
    ]
    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Image url={`${store.getState().projectInfo.shortCode}.png`} height={150} width={345} />
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {
                        digitals.map((item: any, idx: number) =>
                            <DigitalBlock key={idx} type='nameStacking' name={item.name} value={item.value} unit={item.unit} size={0.7} spacing={5} fontSize={30} />)
                    }
                </div>
            </div>
        </React.Fragment>
    )
}