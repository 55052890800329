import { Card, MiniCard } from '@/components/card'
import { BasicLayout, EasyGridLayout, LineLayout } from '@/layout'
import { useRequest } from 'ahooks'
import * as API from '@/api/request'
import { Loading1 } from '@/components/loading'
import { Error } from '@/components/error'
import { useState } from 'react'
import { TimeBar } from '@/components/bar'
import { formatLineChartXAxis, getTimeRangeByUrl } from '@/utils/time'
import { round } from 'lodash'
import { BasicLineChart, StackedLineChart } from '@/components/charts'
import { handleDataForEasyChart } from '@/utils'
import FormatterType from '@/components/charts/formatterType';
const pageName = '车间设备'
const legend = ['产线1', '产线2', '产线3', '产线4', '产线5', '产线6']
const getAllLineChartRataDataY = (data: Array<any>) => {
    return data.map(item => {
        return {
            name: `产线${item.lineCode}`,
            data: item.values.map((child: any) => round(child.count * 100))
        }
    })
}
export const Equipment = () => {
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Equipment.getCurrentElectricityPowerInW(),
            API.Equipment.getlectricityValues(),
            API.Equipment.getUtilizationHours(),
            API.Equipment.getUtilizationRateByTimeRange(null, timeRange),
            API.Equipment.getElectricityQuantityInKWHByTimeRange(),
            API.Equipment.getAllLineUtilizationRate(timeRange)
        ])
    }, { refreshDeps: [timeRange] })
    if (loading) return <BasicLayout name={pageName}>
        <TimeBar change={(timeRange: any) => setTimeRange(timeRange)} size={0.8} />
        <Loading1 />
    </BasicLayout>
    if (error) return <Error />

    const [currentElectricityPower, electricityValues, utilization, utilizationRateByTimeRange, ElectricityQuantityInKWHByTimeRange, allLineUtilizationRate] = data
    console.log(currentElectricityPower, ElectricityQuantityInKWHByTimeRange)
    const miniCards = [
        { title: '稼动率', value: round(utilization.utilizationRate * 100), unit: '%', icon: 'direct.png' },
        { title: '开机时长', value: utilization.usedHours, icon: 'bootTime.png' },
        { title: '待机时长', value: utilization.waitingHours, icon: 'standbyTime.png' },
        { title: '能耗', value: electricityValues.quantityInKWH, icon: 'energyConsumption.png' },
        { title: '能源利用率', value: electricityValues.energyUtilizationInPairPerKWH, icon: 'utilizationRate.png' },
        { title: '当前功率', value: electricityValues.powerInW, icon: 'power.png' },
    ]
    const timeRangeType = (timeRange as any).timeRangeType

    return <BasicLayout name={pageName}>
        <TimeBar change={(timeRange: any) => setTimeRange(timeRange)} size={0.8} />
        <EasyGridLayout columns={2} rows={3} height={300} width={355} gap={10} margin={[10, 0, 0, 0]}  >
            {
                miniCards.map(item => {
                    return <MiniCard title={item.title} value={item.value} icon={item.icon} unit={item.unit} />
                })
            }
        </EasyGridLayout>
        <LineLayout justify='start' gap={10} margin={[10, 0, 0, 0]} width={355} direction='column'>
            <Card title='车间稼动率' height={250} icon='line.png' >
                <BasicLineChart
                    height={200}
                    width={355}
                    dataX={handleDataForEasyChart(utilizationRateByTimeRange, timeRangeType).dataX}
                    dataY={handleDataForEasyChart(utilizationRateByTimeRange, timeRangeType).dataY.map(item => round(item * 100))}
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null} />
            </Card>


            <Card title='各产线稼动率' height={270} icon='line.png' >
                <StackedLineChart
                    legend={legend}
                    height={220}
                    width={355}
                    dataX={formatLineChartXAxis(timeRangeType, allLineUtilizationRate[0].values)}
                    dataY={getAllLineChartRataDataY(allLineUtilizationRate)} />
            </Card>


            <Card title='车间能耗(度)' height={250} icon='line.png' >
                <BasicLineChart
                    height={200}
                    width={355}
                    dataX={handleDataForEasyChart(utilizationRateByTimeRange, timeRangeType).dataX}
                    dataY={handleDataForEasyChart(utilizationRateByTimeRange, timeRangeType).dataY.map(item => round(item * 100))}
                    formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null} />
            </Card>

        </LineLayout>

    </BasicLayout>
}