import React, { useEffect } from 'react';
import * as echarts from 'echarts';

interface Props {
    data: number,
    width: number,
    height: number,
}

/**
 * 图表-基本仪表盘
 * @data number, 数值, 最小0, 最大100
 * @width number, 容器宽度
 * @height number, 容器高度
 */

const BasicGaugeChart = (props: Props) => {

    const id = new Date().getTime().toString() + Math.random();

    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);
            let option;
            let color = new echarts.graphic.LinearGradient(
                0, 0, 1, 0, [{
                    offset: 0,
                    color: "#41D7F3",
                },
                {
                    offset: 1,
                    color: "#3D9FFF",
                }
            ]
            );

            option = {
                series: [{
                    type: 'gauge',
                    radius: '90%',
                    itemStyle: {
                        color: color,
                    },
                    progress: {
                        show: true,
                        width: 4,
                    },
                    axisLine: {
                        lineStyle: {
                            //设置仪表盘的背景颜色
                            color: [[1, '#f8f8f8']],
                            width: 4,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    anchor: {
                        show: true,
                        showAbove: true,
                        size: 12,
                        itemStyle: {
                            color: '#f8f8f8',
                        },
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        show: false,
                    },
                    data: [{
                        value: props.data,
                    }]
                }]
            };

            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} ></ div>
    )
}

export { BasicGaugeChart }