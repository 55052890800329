import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import './index.scss';

interface Props {
    data: number,
    width: number,
    height: number,
    max?: number,
    fontSize?: number,
    name?: string,
    unit?: string,
}

/**
 * 图表-阴影仪表盘
 * @param {number} data 数值, 最小0, 最大100
 * @param {number} width 容器宽度
 * @param {number} height 容器高度
 * @param {number} max 刻度最大值
 * @param {number} fontSize 底部数字字体大小
 * @param {string} name 底部名称
 * @param {string} unit 底部单位
 */

const ShadowGaugeChart = (props: Props) => {

    const id = new Date().getTime().toString() + Math.random();

    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);
            let option;
            let color = new echarts.graphic.LinearGradient(
                0, 0, 1, 0, [{
                    offset: 0,
                    color: "#41D7F3",
                },
                {
                    offset: 1,
                    color: "#3D9FFF",
                }
            ]
            );

            option = {
                series: [{
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    splitNumber: 12,
                    min: 0,
                    max: props.max || 100,
                    itemStyle: {
                        color: color,
                        shadowColor: 'rgba(0,138,255,0.45)',
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    progress: {
                        show: true,
                        roundCap: true,
                        width: 4
                    },
                    pointer: {
                        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                        length: '75%',
                        width: 6,
                        offsetCenter: [0, '5%']
                    },
                    axisLine: {
                        roundCap: true,
                        lineStyle: {
                            width: 4
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        formatter: function (value) {
                            return '{name|' + (props.name || '') + '}{value|' + value.toFixed(0) + '}{unit|' + (props.unit || '') + '}';
                        },
                        rich: {
                            name: {
                                fontSize: 16,
                                color: '#f8f8f8',
                                padding: [0, 10, -20, 0]
                            },
                            value: {
                                fontSize: `${props.fontSize || 20}`,
                                fontWeight: 'bolder',
                                color: '#05D380',
                                padding: [0, 0, -20, 0]
                            },
                            unit: {
                                fontSize: 16,
                                color: '#f8f8f8',
                                padding: [0, 0, -20, 10]
                            }
                        }
                    },
                    data: [{
                        value: props.data
                    }]
                }]
            };

            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} ></div>
    )
}

export { ShadowGaugeChart }