import { ShadowGaugeChart } from '@/components/charts/gauge/shadowGauge'
import { useRequest } from 'ahooks'
import { Equipment } from '@/api/request'
import { Loading2 } from '../loading'
import { round } from 'lodash'
interface Props {
    lineCode: string
}
let content = <Loading2 />

export const Line = (props: Props) => {
    const { data, loading } = useRequest(
        () => Equipment.getCurrentElectricityPowerInW(props.lineCode),
        // { pollingInterval: 10 * 1000 }
    )
    if (loading) return content
    content = <ShadowGaugeChart width={160} height={120} data={round(data / 1000)} unit='kw' name='功率' max={500} />

    return content
}