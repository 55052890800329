//TODO:文件拆分
import { values } from 'lodash';
import { createStore } from 'redux';
const defaultState = {
    projectInfo: {
        name: '',
        shortCode: '',
        id: ''
    },
    hasCallButtonSystem: false,
    cameras: [],
    robots: [],
    installLineCodes: [],
    ovenOrRefrigerators: [],
    camerasCover: {},
    ysyToken: '',
    currentRobotStatus:[],
    homeTabIdx: 0,
    homeTabBar: [],
    glueBuckets: [],
    hasStopperWorkStations: []
}

const reducer = (state: any, action: any) => {
    const { value, type } = action
    switch (type) {
        case 'set_project_info':
            defaultState.projectInfo = value
            break;
        case 'set_has_call_system':
            defaultState.hasCallButtonSystem = value
            break;
        case 'set_cameras':
            defaultState.cameras = value
            break;
        case 'set_robots':
            defaultState.robots = value
            break;
        case 'set_install_lineCodes':
            defaultState.installLineCodes = value
            break;
        case 'set_oven_refrigerator':
            defaultState.ovenOrRefrigerators = value
            break;
        case 'set_ys7_token':
            defaultState.ysyToken = value
            break;
        case 'set_cameras_cover':
            defaultState.camerasCover = value
            break;
        case 'set_home_tab_bar':
            defaultState.homeTabBar=value   
            break; 
         case 'set_home_tab_idx' :
             defaultState.homeTabIdx=value
             break;
         case 'set_current_robot_status':
             defaultState.currentRobotStatus=value
             break
        case 'set_home_tab_idx':
            defaultState.homeTabIdx = value
            break;
        case 'set_glue_bucket':
            defaultState.glueBuckets = value
            break;
        case 'set_has_stopper-work-station':
            defaultState.hasStopperWorkStations = value
            break;
        default:
            break
    }
    return defaultState
}

const store = createStore(reducer);

const setProjectInfo = (value: any) => {
    store.dispatch({
        type: 'set_project_info',
        value
    })
}

const setHasCallButtonSystem = (value: any) => {
    store.dispatch({
        type: 'set_has_call_system',
        value,
    })
}

const setCameras = (value: any) => {
    store.dispatch({
        type: 'set_cameras',
        value,
    })
}

const setRobots = (value: any) => {
    store.dispatch({
        type: 'set_robots',
        value,
    })
}
const setInstallLineCodes = (value: any) => {
    store.dispatch({
        type: 'set_install_lineCodes',
        value,
    })
}
const setOvenOrRefrigerators = (value: any) => {
    store.dispatch({
        type: 'set_oven_refrigerator',
        value,
    })
}

const setYs7Token = (value: any) => {
    store.dispatch({
        type: 'set_ys7_token',
        value,
    })
}
const setCamerasCover = (value: any) => {
    localStorage.setItem('camerasCover', JSON.stringify(value))
    store.dispatch({
        type: 'set_cameras_cover',
        value
    })
}


const setHomeTabBar = (value: any) => {
    store.dispatch({
        type: 'set_home_tab_bar',
        value
    })
}

const setHomeTabIdx = (value: any) => {
    store.dispatch({
        type: 'set_home_tab_idx',
        value
    })
}

const setCurrentRobotStatus =(value:any)=>{
    localStorage.setItem('lastRobotStatus', JSON.stringify(value))
    store.dispatch({
        type:'set_current_robot_status',
        value
    })
}

const setGlueBuckets = (value: any) => {
    store.dispatch({
        type: 'set_glue_bucket',
        value: value
    })
}

const setHasStopperWorkStations = (value: any) => {
    store.dispatch({
        type: 'set_has_stopper-work-station',
        value: value
    })
}

export {
    store,
    setYs7Token,
    setProjectInfo,
    setHasCallButtonSystem,
    setCameras,
    setRobots,
    setInstallLineCodes,
    setOvenOrRefrigerators,
    setCamerasCover,
    setHomeTabBar,
    setHomeTabIdx,
    setCurrentRobotStatus,
    setGlueBuckets,
    setHasStopperWorkStations
}

