import React from 'react';
import { CardBar, RegionBar } from '@/components/bar';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { LineLayout } from '@/layout';
import { BasicUnderLine } from '@/components/underLine';
import { DigitalBlock } from '@/components/digitalBlock';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { searchParams, handleArrayToObject } from '@/utils';

const lineCode = searchParams().lineCode;
let content = <Loading />;
let todayElectricityQuantityInKWH: any, totalElectricityQuantityInKWH: any, lineCurrentElectricities: any;

export const LineEnergy = () => {
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Equipment.getTodayElectricityQuantityInKWH(lineCode),
            API.Equipment.getTotalElectricityQuantityInKWH(lineCode),
            API.Equipment.getLineCurrentElectricities(lineCode),
        ])
    })

    if (loading) {
        return content;
    }

    let energyTem = [
        {
            name: '当前功率',
            data: null,
            unit: 'kW',
        },
        {
            name: '今日能耗',
            data: null,
            unit: '度',
        },
        {
            name: '累积能耗',
            data: null,
            unit: '度',
        },
    ];
    let powerTem = [
        {
            name: 'A相',
            vtem: null,
            atem: null,
        },
        {
            name: 'B相',
            vtem: null,
            atem: null,
        },
        {
            name: 'C相',
            vtem: null,
            atem: null,
        },
    ];

    if (error) {
        return <Error />
    } else {
        [todayElectricityQuantityInKWH, totalElectricityQuantityInKWH, lineCurrentElectricities] = data;

        energyTem = [
            {
                name: '当前功率',
                data: lineCurrentElectricities[0].powerInW / 1000,
                unit: 'kW',
            },
            {
                name: '今日能耗',
                data: todayElectricityQuantityInKWH,
                unit: '度',
            },
            {
                name: '累积能耗',
                data: totalElectricityQuantityInKWH,
                unit: '度',
            },
        ];

        powerTem = [
            {
                name: 'A相',
                vtem: lineCurrentElectricities[0].voltageAInV,
                atem: lineCurrentElectricities[0].currentAInA,
            },
            {
                name: 'B相',
                vtem: lineCurrentElectricities[0].voltageBInV,
                atem: lineCurrentElectricities[0].currentBInA,
            },
            {
                name: 'C相',
                vtem: lineCurrentElectricities[0].voltageCInV,
                atem: lineCurrentElectricities[0].currentCInA,
            },
        ];
    }

    return <React.Fragment>
        <PageBar title={store.getState().projectInfo.name} />
        <RegionBar title='能耗' />
        <Card
            customBar={<React.Fragment />}
            width={345}
            margin={[10, 0, 10, 15]}
        >
            <LineLayout justify='start' direction='column' padding={[0, 0, 0, 0]} width={353}>
                {
                    energyTem.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={item.name} icon={index === 0 ? 'iotEnerykw.png' : 'iotEnerykwh.png'} hideForward size={0.8} width={150} />
                            {index === energyTem.length - 1 ? '' : <BasicUnderLine />}
                            {
                                item ?
                                    <DigitalBlock
                                        value={item.data}
                                        size={0.8} unit={item.unit} width={90} textAlign='right' /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'right' }}>--</p>
                            }
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>
        <Card
            customBar={<React.Fragment />}
            width={345}
            margin={[20, 0, 10, 15]}
        >
            <LineLayout justify='start' direction='column' padding={[0, 0, 0, 0]} width={353}>
                {
                    powerTem.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={item.name} icon={'iotPower.png'} hideForward size={0.8} width={150} />
                            {index === powerTem.length - 1 ? '' : <BasicUnderLine />}
                            {
                                item ?
                                    <DigitalBlock
                                        value={item.vtem}
                                        size={0.8} unit='V' width={90} textAlign='right' /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'right' }}>--</p>
                            }
                            {
                                item ?
                                    <DigitalBlock
                                        value={item.atem}
                                        size={0.8} unit='A' width={90} textAlign='right' /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'right' }}>--</p>
                            }
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>
    </React.Fragment>
}