import React, { useEffect } from 'react';
import * as echarts from 'echarts';

interface Props {
    data: Array<ObjectData>,
    width: number,
    height: number,
}

interface ObjectData {
    name: string,
    value: number,
}

/**
 * 图表-矩形树图
 * @data number, Array<{name: string, value: number}> 数值
 * @width number, 容器宽度
 * @height numebr, 容器高度
 */

const TreeMapChart = (props: Props) => {

    const id = new Date().getTime().toString() + Math.random();

    useEffect(() => {
        let chartDom = document.getElementById(id) as HTMLElement;
        if (chartDom) {
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                series: [{
                    type: 'treemap',
                    breadcrumb: { show: false },
                    data: props.data,
                }]
            };

            option && myChart.setOption(option);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div id={id} style={{ width: `${props.width}px`, height: `${props.height}px` }} ></ div>
    )
}

export { TreeMapChart }