
import { EasyGridLayout, LineLayout } from '@/layout'
import React, { useEffect, useState } from 'react'
import { LiveCover } from '@/components/liveCover'
import { store } from '@/store'
import { useHistory } from 'react-router-dom'
import { Collapes } from '@/components/collapse'
import { Loading1 } from '@/components/loading'
import { PageBar } from '@/components/bar'
import './index.scss'
export const LiveNavigation = () => {
    const history = useHistory()
    const [cameras] = useState(store.getState().cameras)
    const [camerasCover] = useState(store.getState().camerasCover)
    const pageName = store.getState().projectInfo.name;
    const clickFullScreen = (id: number) => {
        history.push(`/live?cameraId=${id}`)
    }




    let globalCameras= []
    // let globalCameras = cameras.filter((item: any) => {
    //     return item.workStationCode === null ||
    //         item.workStationCode === '101' ||
    //         item.workStationCode === '102' ||
    //         item.workStationCode === '103'
    // })
    // let workStationCameras = cameras.filter((item: any) => {
    //     return item.workStationCode !== null &&
    //         item.workStationCode !== '101' &&
    //         item.workStationCode !== '102' &&
    //         item.workStationCode !== '103'
    // })


    //特殊处理一下 选几个摄像头出来 具体什么方案没什么想法
    if(store.getState().projectInfo.shortCode === 'D2') {
    
      // workStationCameras=[cameras[7],cameras[8],cameras[2],cameras[0]]
    }

    if(store.getState().projectInfo.shortCode === 'S1') {
        globalCameras=[cameras[0],cameras[5],cameras[7]]
      //  globalCameras=[cameras]

    }

    return <React.Fragment>
        <PageBar title={'实况直播'} />
        {globalCameras[0] === undefined ? <Loading1 /> :
            <React.Fragment>

                <LineLayout justify='between' padding={[20, 0, 0, 20]}>
                    <div style={{ color: '#fff', fontSize: '20px' }}>{globalCameras.length }个产线直播</div>
                </LineLayout>

                <div className='cover-contents'>
                    {
                        globalCameras.map((item: any, idx: number) => {
                            return <LiveCover
                                deviceSerial={item.deviceSerial}
                                channelNo={item.channelNo}
                                cameraId={item.id}
                                name={item.name}
                                clickFullScreen={() => { clickFullScreen(item.id) }}
                                coverUrl={camerasCover ? camerasCover[item.id] : null}
                                url={item.yS7UrlFluency} />
                        })
                    }

                </div>


                {/* <LineLayout justify='between' padding={[20, 0, 0, 20]}>
                    <div style={{ color: '#fff', fontSize: '20px' }}>{workStationCameras.length}个加工站直播</div>
                </LineLayout> */}

                {/* <div className='cover-contents'>
                    {
                        workStationCameras.map((item: any, idx: number) => {
                            return <LiveCover
                                deviceSerial={item.deviceSerial}
                                channelNo={item.channelNo}
                                cameraId={item.id}
                                name={item.name}
                                clickFullScreen={() => { clickFullScreen(item.id) }}
                                coverUrl={camerasCover ? camerasCover[item.id] : null}
                                url={item.yS7UrlFluency} />
                        })
                    }

                </div> */}

            </React.Fragment >
        }
    </React.Fragment >
}
