import React, { useState } from 'react';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { searchParams } from '@/utils';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd-mobile';
import * as Thumbmail from '@/components/thumbnail'
export const CallNavigation = () => {
    const history = useHistory();
    const [lineCode] = useState(searchParams().lineCode);
    const pageName = store.getState().projectInfo.name;
    return <React.Fragment>
        <PageBar title={'呼叫管理'} />
        <Card title='呼叫' icon='call.png' width={345} height={240} margin={[10, 0, 0, 15]} children={<Thumbmail.Call />} />
    </React.Fragment >
}
