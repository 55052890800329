//处理时刻数据
const handlePromptBoxTime = (time: any) => {
    if (time < 10) {
        return `0${time}:00`;
    } else {
        return `${time}:00`;
    }
}

const FormatterType = {

    //格式一: 时刻提示框
    TodayMoment: (arg: any) => {
        return (
            handlePromptBoxTime(Number(arg[0].name)) +
            '-' +
            handlePromptBoxTime(Number(arg[0].name) + 1) +
            '<br />' +
            `<div style="display: flex; align-items: center;">
                <div style="width: 10px; height: 10px; border-radius: 10px; background-color: #4992ff;"></div>
                <div style="font-weight: bold; margin-left: 15px;">${(arg[0].value === undefined ? '-' : arg[0].value)}</div>
            </div>
            `
        )
    }
}

export default FormatterType;

