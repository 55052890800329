import React from 'react'
import './index.scss'

interface ErrorProps {
    value?: any,
    size?:number
}


/**
 * TODO
 * 网络错误掉线了
 * 服务器错误接口挂掉了
 */


export const Error = (props: ErrorProps) => {
    console.log(props)
    return <div className='error' style={{zoom:props.size || 1}}>
        <div className='error-pic'></div>
        <p className='description'>{props.value || '未知错误'}</p>
    </div>
}
