//当前开发环境对应的项目 d2温州 d1成都 y1为温州连帮注射线
const currentProjectDev = "X"
const hostname = window.location.hostname
const projectName = hostname.split('.')[1]
let requestBaseUrl = ''
let productionRequestUrl = ''
if (hostname.includes('zs-tek')) {
  productionRequestUrl = `https://api.${projectName}.zs-tek.com`
} else {
  productionRequestUrl = `http://${hostname}:11003`
}
switch (process.env.NODE_ENV) {
  case 'development':
    requestBaseUrl = `http://api.${currentProjectDev}.zs-tek.com`
    break;
  case 'production':
    requestBaseUrl = productionRequestUrl
    if(projectName==='s1' || projectName==='s'){ 
      requestBaseUrl = `https://api.s1.zs-tek.com`

    }
    break;
  default:
    requestBaseUrl = `http://api.${currentProjectDev}.zs-tek.com`
}

export { requestBaseUrl }

