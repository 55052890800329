import React from 'react';
import { CardBar, RegionBar } from '@/components/bar';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { LineLayout } from '@/layout';
import { Image } from '@/components/image';
import { BasicUnderLine } from '@/components/underLine';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { searchParams, handleArrayToObject } from '@/utils';
import { ExportButton } from '@/components/exportButton';
import { DigitalBlock } from '@/components/digitalBlock';
import _ from 'lodash';

const lineCode = searchParams().lineCode;
let content = <Loading />;
let workstationCurrentPalletCounts: any, statisticValuesByWorkStation: any;
let logisticsArr: any = [];

//数组按对象里的属性排序
const handleArraySort = (propertyName: any) => {
    return function (obj1, obj2) {
        var value1 = obj1[propertyName];
        var value2 = obj2[propertyName];
        if (value1 < value2) {
            return -1
        } else if (value1 > value2) {
            return 1
        } else {
            return 0
        }
    }
}

export const Logistics = () => {
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Logistics.getWorkstationCurrentPalletCounts(lineCode),
            API.Logistics.getStatisticValuesByWorkStation(lineCode),
        ])
    })

    if (loading) {
        return <><PageBar title={'物流'} />{content}</>;
    }

    if (error) {
        return <><PageBar title={'物流'} /><Error /></>
    } else {
        [workstationCurrentPalletCounts, statisticValuesByWorkStation] = data;
        logisticsArr = statisticValuesByWorkStation.sort(handleArraySort('isFullHours')).reverse().slice(0, 3);
        // console.log('数据', logisticsArr);
        if (logisticsArr[0].usedHours === 0) {
            logisticsArr = []
        }

    }

    return <React.Fragment>
        <PageBar title={'物流'} />
        {/* <RegionBar title='物流' /> */}
        <Card
            customBar={<React.Fragment />}
            width={345}
            margin={[10, 0, 10, 15]}
        >
            <LineLayout justify='start' direction='column' padding={[10, 0, 0, 0]} width={353}>
                <Image url={`iot${store.getState().projectInfo.shortCode}Logistics.png`} height={160} width={325} />
                {
                    store.getState().hasStopperWorkStations.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={`${index + 1}-${item.name}`} icon={''} hideForward size={0.8} width={150} />
                            {index === store.getState().hasStopperWorkStations.length - 1 ? '' : <BasicUnderLine />}
                            {
                                handleArrayToObject(workstationCurrentPalletCounts, 'workStationCode')[item.code] !== undefined ?
                                    <CardBar
                                        title={handleArrayToObject(workstationCurrentPalletCounts, 'workStationCode')[item.code].isFull ? '满料' : '正常'}
                                        size={0.8} width={70} icon={''} hideForward
                                        color={handleArrayToObject(workstationCurrentPalletCounts, 'workStationCode')[item.code].isFull ? '#ff2800' : 'rgb(5, 211, 128)'}
                                        fontWeight
                                    /> :
                                    <p style={{ fontWeight: 'bold', width: '72px' }}>--</p>
                            }
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>

        <Card
            width={345}
            margin={[10, 0, 0, 15]}
            title='今日满料时长:'
        >
            <LineLayout justify='start' direction='column' padding={[0, 0, 0, 0]} width={353}>
                {
                    logisticsArr ?
                        logisticsArr.map((item: any, index: number) => {
                            return <LineLayout justify='around' width={325} key={index}>
                                <CardBar title={`${item.workStationCode}-${item.workStationName}`} icon={''} hideForward size={0.8} width={140} />
                                {index === logisticsArr.length - 1 ? '' : <BasicUnderLine />}
                                {
                                    <DigitalBlock value={_.round(item.isFullHours, 2)} name='' valueType='time' size={0.85} spacing={90} color={'orange'} />
                                }
                            </LineLayout>
                        }) : ''
                }
            </LineLayout>
            <ExportButton api='/Export/ExportLogisticsReport' title={'物流报表'} />
        </Card>
    </React.Fragment >
}