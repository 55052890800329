import { BasicLineChart } from "@/components/charts";
import { DigitalBlock } from "@/components/digitalBlock";
import { LineLayout } from '@/layout'
import { useRequest } from "ahooks";
import * as API from '@/api/request'
import { handleDataForEasyChart, searchParams } from "@/utils";
import { Loading2 } from "@/components/loading";
import { Error } from "@/components/error";
import { Fragment } from "react";
import moment from "moment";
import { timeRanges } from "@/utils/time";
import './index.scss';
import { setInterval } from "timers";


export const Production4 = () => {
    const { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Production.getCountByLast15Days(),
            // API.Production.getCountByLast15Days("2"),
            // API.Production.getCountByLast15Days("3")
        ])
    })
    if (loading) return <Loading2 />
    if (error) return <Error size={0.5} />
    const CountBy15days = data[0]
    // const [s1CountBy15days,s2CountBy15days,s3CountBy15days] = data
    // const allCountBy15days = s1CountBy15days.map((item:any,idx:number)=>{
    //      item.count = item.count+s2CountBy15days[idx].count+ s3CountBy15days[idx].count
    //      return item

    // })
    return (
        <Fragment>
                <BasicLineChart
                    // hideYAxis
                    width={365}
                    height={200}
                    dataX={CountBy15days.map((item: any) => moment(item.timeRange.beginTime).format('DD'))}
                    dataY={handleDataForEasyChart(CountBy15days).dataY} />
        </Fragment>
    );
};
