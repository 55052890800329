import { Icon } from '../icon'
import { Modal } from 'antd-mobile'
import { requestBaseUrl } from '@/api/initEnv';
import { searchParams } from '@/utils';
import service from '@/api/service';
import './index.scss'
interface ExportButtonProps {
    api: string,
    title?: string,
}
export const ExportButton = (props: ExportButtonProps) => {
    let title = props.title ? '导出' + props.title : '导出数据';
    const confirmExport = () => {
        Modal.alert(title, '确定下载该文件吗？', [
            { text: '取消', onPress: () => { } },
            {
                text: '确定',
                onPress: () => {
                    window.location.href = `${requestBaseUrl}${props.api}?lineCode=${searchParams().lineCode}`
                }
            },
        ])
    }
    return <div className='export-button'>
        <div onClick={confirmExport}>
            <Icon iconName='export.png' size={1.4} />
        </div>
        <p>{props.title}</p>
    </div>

}