import React from 'react';
import { CardBar, RegionBar } from '@/components/bar';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { EasyGridLayout, LineLayout } from '@/layout';
import { BasicUnderLine } from '@/components/underLine';
import { DigitalBlock } from '@/components/digitalBlock';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { searchParams, handleArrayToObject } from '@/utils';
import { Circle } from '@/components/circle';
import { Icon } from '@/components/icon';

const lineCode = searchParams().lineCode;
let content = <Loading />;
let currentEnvironmentValues: any;

export const Environment = () => {
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Environment.getCurrentEnvironmentValues(lineCode),
        ])
    })

    if (loading) {
        return content;
    }

    let environmentValuesTem = [
        {
            name: '温度',
            data: null,
            unit: '℃',
            icon: 'temperature.png',
        },
        {
            name: '湿度',
            data: null,
            unit: '%',
            icon: 'humidity.png',
        },
        {
            name: '噪音',
            data: null,
            unit: 'dB',
            icon: 'noise.png',
        },
        {
            name: 'PM2.5',
            data: null,
            unit: 'ug/m3',
            icon: 'PM25.png',
        }
    ];
    if (error) {
        return <Error />
    } else {
        [currentEnvironmentValues] = data;

        environmentValuesTem = [
            {
                name: '温度',
                data: currentEnvironmentValues.temperatureInC,
                unit: '℃',
                icon: 'temperature.png',
            },
            {
                name: '湿度',
                data: currentEnvironmentValues.humidness * 100,
                unit: '%',
                icon: 'humidity.png',
            },
            {
                name: '噪音',
                data: currentEnvironmentValues.noiseIndB,
                unit: 'dB',
                icon: 'noise.png',
            },
            {
                name: 'PM2.5',
                data: currentEnvironmentValues.pM25,
                unit: 'ug/m3',
                icon: 'PM25.png',
            }
        ];
    }

    return <React.Fragment>
        <PageBar title={store.getState().projectInfo.name} />
        <RegionBar title='环境' />
        <EasyGridLayout width={355} height={170} rows={2} columns={2} padding={[0, 0, 0, 0]} center gap={10}>
            {environmentValuesTem.map((item: any, index: number) => {
                return (
                    <Card customBar={<React.Fragment />} key={index}>
                        <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                            <Circle>
                                <Icon iconName={item.icon} size={1.2} />
                            </Circle>
                            <DigitalBlock type='valueStacking' value={item.data} name={item.name} unit={item.unit} size={0.85} spacing={10} />
                        </LineLayout>
                    </Card>
                )
            })}
        </EasyGridLayout>

    </React.Fragment>
}