import React, { useState } from 'react';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import * as Thumbmail from '@/components/thumbnail';
import './index.scss';
import { searchParams } from '@/utils';
import { useHistory } from 'react-router-dom'
export const HomeEdition2 = () => {
    const history = useHistory();
    const [lineCode] = useState(searchParams().lineCode);
    const shortCode = store.getState().projectInfo.shortCode
    let pageName = `ZS-TEK S${lineCode} 智能产线`
    if (shortCode === "S1") {
        if (lineCode === "2") {
            pageName = `ZS-TEK S2-1 智能产线`
        }
        if (lineCode === "4") {
            pageName = `ZS-TEK S2-2 智能产线`
        }
    }

    if(shortCode==="X") {
        pageName = `ZS-TEK 东才X${lineCode} 智能产线`

    }

    const hasCallButtonSystem = store.getState().hasCallButtonSystem
    let buttonData = [
        { title: '生产', jumpTo: `/line/production?lineCode=${lineCode}` },
        { title: '产能', jumpTo: `/line/capacity?lineCode=${lineCode}` },
        { title: '设备', jumpTo: `/line/oven?lineCode=${lineCode}` },
        { title: '能源', jumpTo: `/line/equipment?lineCode=${lineCode}` },
        { title: '实况', jumpTo: `/line/liveNavigation?lineCode=${lineCode}` },
    ]
    if (hasCallButtonSystem) buttonData.push({ title: '呼叫', jumpTo: `/line/callNavigation?lineCode=${lineCode}` })
    return <React.Fragment>
        <PageBar title={pageName} />
        <Card
            width={345}
            margin={[10, 0, 10, 15]}
            height={200}
            customBar={<></>}
            children={<Thumbmail.Environment2 />}
        />
        <div className='homeEdition2-bottom'>
            <div className='homeEdition2-bottom-left'>
                <div className='homeEdition2-bottom-left-list'>
                    {buttonData.map((item: any, index: number) => {
                        return <div className={`homeEdition2-bottom-left-list-button`} onClick={() => { history.push(item.jumpTo); }}>{item.title}</div>
                    })}
                </div>
            </div>
            <div className='homeEdition2-bottom-right'>
                <Card
                    width={252}
                    margin={[15, 0, 10, 15]}
                    height={360}
                    customBar={<></>}
                    children={<Thumbmail.Production3 />}
                />
            </div>
        </div>
    </React.Fragment >
}
