
import './index.scss';
import { useHistory } from 'react-router-dom'
import { store } from '@/store';
const Home = () => {
    const history = useHistory()
    const installLineCodes = store.getState().installLineCodes
    if (Array.isArray(installLineCodes) && installLineCodes.length < 2) {
        history.push('/line?lineCode=' + 1)
    } else {
        history.push('/workShop')
    }
    return null
}
export { Home };