import React, { Fragment, useState, useEffect } from 'react';
import './index.scss';
import { TimeBar, RegionBar, CardBar } from '@/components/bar';
import { BasicLayout, LineLayout, EasyGridLayout } from '@/layout';
import { Card } from '@/components/card';
import { DigitalBlock } from '@/components/digitalBlock';
import { Icon } from '@/components/icon';
import { Circle } from '@/components/circle';
import * as Charts from '@/components/charts';
import { BasicUnderLine } from '@/components/underLine';
import { Uikit } from '@/components/uikit';
import { searchParams, handleDataForLineChart } from '@/utils';
import { timeRanges } from '@/utils/time';
import useRequest from '@ahooksjs/use-request';
import { Quality, Equipment } from '@/api/request';
import _ from 'lodash';
import Loading from '@/components/loading/loading';
import classNames from 'classnames';
import { store } from '@/store';
import FormatterType from '@/components/charts/formatterType';
import { requestBaseUrl } from '@/api/initEnv';


const WorkStation = () => {

    const robotStatus = [
        { icon: ['sk10.png'], value: [] },
        { icon: ['sk11.png'], value: ['总计：'] },
        { icon: ['sk1.png'], value: ['初始化', '伺服关闭', '伺服开启', '戒备状态', '紧急停止', '急停恢复', '系统失效', '未知'] },
        { icon: ['sk2.png'], value: ['自动', '手动减速', '手动全速', '自动全速准备', '手动全速准备', '未定义状态'] },
        { icon: ['sk9.png'], value: ['速度: '] },
        { icon: ['sk4err.png', 'sk4.png'], value: ['不在原点', '到达原点'] },
        { icon: ['sk5.png', 'sk5err.png'], value: ['执行正常', '执行异常'] },
        { icon: ['sk6.png', 'sk6err.png'], value: ['温度正常', '温度异常'] },
        { icon: ['sk7.png', 'sk7err.png'], value: ['风扇正常', '风扇异常'] },
        { icon: ['sk8.png', 'sk8err.png'], value: ['电池正常', '电池异常'] },
    ];

    // const statoRobot = ['', '', '', '', '', '', '', '', '',]

    const lineCode = useState(searchParams().lineCode)[0]
    const workStationCode = useState(searchParams().workStationCode)[0]
    const [timeRange, setTimeRange] = useState({ ...timeRanges.today(), timeRangeType: 0 });
    const [videoLoading, setVideoLoading] = useState(true);
    const [robotCurrentStatus, setRobotCurrentStatus] = useState([true, false, false, false,]);
    const [qualityLoading, setQualityLoading] = useState(true);
    const [qualityCard, setQualityCard] = useState([100, 300, 300]);
    const [firstPassYield, setFirstPassYield] = useState([]);
    const [shoeTest, setShoeTest] = useState({
        shoeTypeCodeInRFID: undefined,
        sizeForHuman: undefined,
        posizione: '',
        result: '',
        qualityCheckRecordId: undefined,
    });
    const [testPicture, setTestPicture] = useState([]);

    useRequest(() => {
        console.log('lineCode, workStationCode', lineCode, workStationCode);
        return Promise.all([
            Equipment.getCameras(lineCode, workStationCode),
            Quality.getLastQualityCheckRecord(lineCode, workStationCode),
            Equipment.getRobotCurrentStatusByLocation(lineCode, workStationCode),
        ])
    }, {
        onSuccess: (data) => {

            setVideoLoading(false);
            setShoeTest({
                sizeForHuman: data[1].sizeForHuman,
                shoeTypeCodeInRFID: data[1].shoeTypeNameForHuman,
                posizione: data[1].isRightOnly === true ? '右脚' : data[1].isRightOnly === false ? '左脚' : '双脚',
                result: data[1].isNotGood ? 'NG' : 'OK',
                qualityCheckRecordId: data[1].id,
            })
            let shoeInformation = (data[2].shoeTypeNameForHuman || '') + '-' + (data[2].shoeSizeForHuman || '') + '-' + (data[2].shoeIsRight === undefined || data[2].shoeIsRight === null ? '' : data[2].shoeIsRight ? '右' : '左');
            console.log('机器人状态', data[2]);
            setRobotCurrentStatus([shoeInformation, parseFloat(data[2].shoesCount || 0).toLocaleString(), data[2].controllerOperationMode, data[2].controllerState, data[2].speedRatio || 0, data[2].onHomePosition, data[2].hasExecutionError, data[2].temperatureIsWarning, data[2].cpuFanIsStop, data[2].batteryIsLow])
        }
    })

    useEffect(() => {
        Quality.getQualityCheckPictureRelativeUrls(shoeTest.qualityCheckRecordId).then((res: any) => {
            setTestPicture(res);
        })
    }, [shoeTest])

    useRequest(() => {
        setQualityLoading(true);
        return Promise.all([
            Quality.getStatisticValues(lineCode, timeRange, workStationCode),
            Quality.getFirstPassYieldByTimeRange(lineCode, timeRange, workStationCode),
        ])
    }, {
        onSuccess: (data) => {
            let cards = qualityCard;
            cards[0] = _.round(data[0].firstPassYield * 100, 1);
            cards[1] = data[0].okCount;
            cards[2] = data[0].notGoodCount;
            setFirstPassYield(data[1]);
            setQualityCard(cards);
            setQualityLoading(false);
        },
        refreshDeps: [timeRange]
    })

        let pageName = ''
    const robot = store.getState().robots.find((item: any) => item.workStationCode === workStationCode)
    let camera = store.getState().cameras.find((item: any) => item.id === parseInt(searchParams().cameraId))
    if (!camera) {
        camera = store.getState().cameras.find((item: any) => item.workStationCode === workStationCode)
    }
    if (robot) {
        pageName = robot.name
    } else {
        pageName = camera.name
    }

    if (workStationCode === '101' || workStationCode === '102' || workStationCode === '103' || !workStationCode) {
        return (
            <BasicLayout name={pageName}>
                <RegionBar title='视频直播' />
                <LineLayout justify='start' height={230} direction='column'>
                    {!videoLoading ? <Uikit width={355} height={230} url={camera.yS7UrlFluency} token={camera.yS7AccessToken} />
                        : <Loading />
                    }
                </LineLayout>
            </BasicLayout>
        )
    }

    return (
        <BasicLayout name={pageName}>

            {
                camera ?
                    <Fragment>
                        <RegionBar title='视频直播' />
                        <LineLayout justify='start' height={230} direction='column' margin={[0, 0, 20, 0]}>
                            {!videoLoading ?

                                <Uikit width={355} height={230} url={camera.yS7UrlFluency} token={camera.yS7AccessToken} />
                                : <Loading />
                            }
                        </LineLayout>
                    </Fragment> : null
            }
            {workStationCode === '8' || workStationCode === '9' ? ''
                :
                <React.Fragment>
                    <RegionBar title='实时品质检测' />
                    <Card customBar={<React.Fragment />} height={600}>
                        <LineLayout justify='start' direction='column'>
                            <LineLayout justify='between' width={345}>
                                <CardBar title='检测鞋型' icon='model.png' hideForward size={0.8} />
                                <BasicUnderLine />
                                <div className='workStation-text'>{shoeTest.shoeTypeCodeInRFID}</div>
                                <div className='workStation-text'>{shoeTest.sizeForHuman}码</div>
                                <div className='workStation-text'>{shoeTest.posizione}</div>
                            </LineLayout>
                            <LineLayout justify='between' width={345}>
                                <div>
                                    <img className='workStation-testing-image' src={`${requestBaseUrl}/${testPicture[0]}`} />
                                    <img className='workStation-testing-image' src={`${requestBaseUrl}/${testPicture[1]}`} />
                                </div>
                                <div className={classNames({
                                    'workStation-testing-result': true,
                                    'workStation-testing-NG': shoeTest.result === 'NG',
                                })}  >{shoeTest.result}</div>
                            </LineLayout>
                        </LineLayout>
                    </Card>
                </React.Fragment>}
            <RegionBar title='设备状态' />
            <LineLayout justify='start' height={300} direction='column'>
                <Card customBar={<React.Fragment />}>
                    <EasyGridLayout rows={5} columns={2} height={300} width={355} margin={[0, 0, 0, 0]} center>
                        {
                            robotStatus.map((item: any, index: number) => {
                                return <LineLayout justify='start' gap={10} key={index} width={150}>
                                    {
                                        index < 5 ?
                                            <Icon iconName={item.icon[0]} size={1.2} />
                                            :
                                            <Icon iconName={robotCurrentStatus[index] ? item.icon[1] : item.icon[0]} size={1.2} />
                                    }
                                    {
                                        index === 0 ?
                                            <span className='workStation-robot-status-value'>{robotCurrentStatus[index]}</span>
                                            :
                                            index > 1 && index < 4 ?
                                                <span className='workStation-robot-status-value'>{item.value[Number(robotCurrentStatus[index])]}</span>
                                                :
                                                index === 1 || index === 4 ?
                                                    <span className='workStation-robot-status-value'>{`${item.value[0]}${robotCurrentStatus[index]}`}</span>
                                                    :
                                                    <span className='workStation-robot-status-value'>{robotCurrentStatus[index] ? item.value[1] : item.value[0]}</span>
                                    }
                                </LineLayout>
                            })
                        }
                    </EasyGridLayout>
                </Card>
            </LineLayout>
            <RegionBar title='生产质量' />
            <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} selected={timeRange.timeRangeType} size={0.85} height={35} />
            {
                !qualityLoading ?
                    <React.Fragment>
                        <Card customBar={<React.Fragment />} >
                            <LineLayout justify='start' padding={[0, 0, 0, 10]} height={70} gap={10}>
                                <Circle>
                                    <Icon iconName='direct.png' size={1.2} />
                                </Circle>
                                <DigitalBlock value={qualityCard[0]} name='直通率' unit='%' size={0.85} spacing={150} />
                            </LineLayout>
                        </Card>
                        <EasyGridLayout width={355} height={85} rows={1} columns={2} padding={[10, 0, 0, 0]} center gap={10}>
                            <Card customBar={<React.Fragment />}>
                                <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                                    <Circle>
                                        <Icon iconName='goodProduct.png' size={1.2} />
                                    </Circle>
                                    <DigitalBlock type='valueStacking' value={qualityCard[1]} name='加工成功' unit='次' size={0.85} spacing={10} />
                                </LineLayout>
                            </Card>
                            <Card customBar={<React.Fragment />}>
                                <LineLayout justify='start' padding={[0, 0, 0, 10]} height={80} gap={10}>
                                    <Circle>
                                        <Icon iconName='badProduct.png' size={1.2} />
                                    </Circle>
                                    <DigitalBlock type='valueStacking' value={qualityCard[2]} name='缺陷次数' unit='次' size={0.85} spacing={10} />
                                </LineLayout>
                            </Card>
                        </EasyGridLayout>
                        <LineLayout justify='around' padding={[15, 0, 15, 0]} gap={10} direction='column'>
                            <Card title='直通率(%)' icon='line.png'>
                                <Charts.BasicLineChart width={355} height={200}
                                    dataX={handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).xAxis}
                                    dataY={handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).yAxis.map((item: any) => {
                                        return item === undefined || item === null ? item : _.round(item * 100, 1);
                                    })}
                                    formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null}
                                />
                            </Card>
                        </LineLayout>
                    </React.Fragment> : <Loading />
            }
        </BasicLayout>
    )
}

export { WorkStation }