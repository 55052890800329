import React from 'react'
import './index.scss'
interface Props {
    size?: number,
    iconName: string,
    rotate?: number
}

//TODO:如果没有传入后缀则自动添加png或者jpg


const publicUrl = process.env.PUBLIC_URL
export const Icon = (props: Props) => {
    const iconStyle = {
        transform: `rotate(${props.rotate}deg)`,
        width: '20px',
        height: '20px',
        zoom: props.size,
        backgroundImage: `url(${publicUrl + '/icon/' + props.iconName})`,
    }
    return (
        <div
            className="icon-content"
            style={iconStyle}>
        </div>
    )
}
