import React from 'react';
import { CardBar, RegionBar } from '@/components/bar';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { LineLayout } from '@/layout';
import { Image } from '@/components/image';
import { BasicUnderLine } from '@/components/underLine';
import { DigitalBlock } from '@/components/digitalBlock';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { searchParams, handleArrayToObject } from '@/utils';

const lineCode = searchParams().lineCode;
let content = <Loading />;
let ovensCurrentParameters: any;

export const Oven = () => {
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Equipment.getOvensCurrentParameters(lineCode),
        ])
    })

    if (loading) {
        return content;
    }

    if (error) {
        return <Error />
    } else {
        [ovensCurrentParameters] = data;
    }

    return <React.Fragment>
        <PageBar title={store.getState().projectInfo.name} />
        <RegionBar title='烘箱' />
        <Card
            customBar={<React.Fragment />}
            width={345}
            margin={[10, 0, 10, 15]}
        >
            <LineLayout justify='start' direction='column' padding={[10, 0, 0, 0]} width={353}>
                <Image url={`iot${store.getState().projectInfo.shortCode}Oven.png`} height={160} width={325} />
                {
                    store.getState().ovenOrRefrigerators.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={`${index + 1}-${item.name}`} icon={''} hideForward size={0.8} width={150} />
                            {index === store.getState().ovenOrRefrigerators.length - 1 ? '' : <BasicUnderLine />}
                            {
                                handleArrayToObject(ovensCurrentParameters, 'ovenCode')[item.code] !== undefined ?
                                    <DigitalBlock
                                        value={handleArrayToObject(ovensCurrentParameters, 'ovenCode')[item.code].temperatureInC}
                                        size={0.8} unit='℃' width={90} textAlign='right'
                                        color={item.name === '冷定型' ? '#00e2ff' : item.name === '热定型' ? '#ff2800' : 'orange'}
                                    /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'right' }}>--</p>
                            }
                            {
                                handleArrayToObject(ovensCurrentParameters, 'ovenCode')[item.code] !== undefined ?
                                    <DigitalBlock
                                        value={handleArrayToObject(ovensCurrentParameters, 'ovenCode')[item.code].powerInW / 1000}
                                        size={0.8} unit='kw' width={90} textAlign='right' /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'right' }}>--</p>
                            }
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>
    </React.Fragment>
}