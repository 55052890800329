import React from 'react';
import { CardBar, RegionBar } from '@/components/bar';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { LineLayout } from '@/layout';
import { Image } from '@/components/image';
import { BasicUnderLine } from '@/components/underLine';
import { DigitalBlock } from '@/components/digitalBlock';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { searchParams, handleArrayToObject } from '@/utils';
import AntTabBar from 'antd-mobile/lib/tab-bar';

const lineCode = searchParams().lineCode;
let content = <Loading />;
let injectionMachineCurrentParameters: any;

export const Syringe = () => {
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Equipment.getInjectionMachineCurrentParameters(lineCode),
        ])
    })

    let rubberTankData = [
        {
            code: "1",
            content: null,
            name: 'A1',
        },
        {
            code: "2",
            content: null,
            name: 'A2',
        },
        {
            code: "3",
            content: null,
            name: 'B',
        },
    ]
    let temSize = null;
    let syringeData = new Array(30).fill('');

    if (loading) {
        return <><PageBar title={'注射器'} />{content}</>;
    }

    if (error) {
        return <><PageBar title={'注射器'} /><Error /></>
    } else {
        [injectionMachineCurrentParameters] = data;
        if (injectionMachineCurrentParameters.length) {
            rubberTankData = [
                {
                    code: "1",
                    content: injectionMachineCurrentParameters.a1MaterialQuantityInG,
                    name: 'A1',
                },
                {
                    code: "2",
                    content: injectionMachineCurrentParameters.a2MaterialQuantityInG,
                    name: 'A2',
                },
                {
                    code: "3",
                    content: injectionMachineCurrentParameters.bMaterialQuantityInG,
                    name: 'B',
                },
            ];
            temSize = injectionMachineCurrentParameters.currentStationNumber;
            syringeData.forEach((item: any, index: number) => {
                item = injectionMachineCurrentParameters[`station${index}IsEnable`];
            })
        }
    }

    return <React.Fragment>
        <PageBar title={'注射器'} />
        {/* <RegionBar title='胶罐' /> */}
        <Card
            customBar={<React.Fragment />}
            width={345}
            margin={[10, 0, 10, 15]}
        >
            <LineLayout justify='start' direction='column' padding={[10, 0, 0, 0]} width={353}>
                <Image url={`iot${store.getState().projectInfo.shortCode}Syringe.png`} height={160} width={325} />
                <LineLayout justify='around' width={325}>
                    <CardBar title={'当前位置'} icon={''} hideForward size={0.8} width={135} />
                    <BasicUnderLine />
                    {
                        temSize ?
                            <DigitalBlock
                                value={temSize}
                                size={0.8} unit='号' width={115} textAlign='right' /> :
                            <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'center' }}>--</p>
                    }
                </LineLayout>
                {
                    syringeData.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={`${index + 1}`} icon={''} hideForward size={0.8} width={150} />
                            {index === rubberTankData.length - 1 ? '' : <BasicUnderLine />}
                            <CardBar
                                title={item ? '开机' : '关机'}
                                size={0.8} width={60} icon={''} hideForward
                                color={item ? 'rgb(5, 211, 128)' : '#ff2800'}
                            />
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>

        <Card
            width={345}
            margin={[10, 0, 0, 15]}
            title='料罐：'
        >
            <LineLayout justify='start' direction='column' padding={[0, 0, 0, 0]} width={353}>
                {
                    rubberTankData.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={`${index + 1}-${item.name}`} icon={''} hideForward size={0.8} width={150} />
                            {index === rubberTankData.length - 1 ? '' : <BasicUnderLine />}
                            {
                                item.content !== null ?
                                    <DigitalBlock
                                        value={item.content}
                                        size={0.8} unit='g' width={115} textAlign='right' /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'center' }}>--</p>
                            }
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>
    </React.Fragment>
}