import service from '../service'
import moment from 'moment'

const today = { beginTime: moment().format('YYYY-MM-DD'), endTime: moment().add(1, 'day').format('YYYY-MM-DD') }
const Call = {
    getTodayCallCount: (lineCode?: string) => {
        return service({
            url: '/Call/GetCountByCallTypes',
            params: { lineCode: lineCode, ...today, timeRangeType: 2 }
        })
    },

    /**
     * 根据时间和产线返回所有呼叫类型呼叫次数
     * @param lineCode 
     * @returns 
     */
    getCountByTimeRangeByCallType: (lineCode?: string | null, timeRange?: { beginTime: string, endTime: string } | {}) => {
        return service({
            url: '/Call/GetCountByTimeRangeByCallType',
            params: { lineCode, ...timeRange }
        })
    },

    /**
     * 获取正在呼叫的呼叫记录
     * @param lineCode 产线
     * @returns 
     */
    getCallingRecords: (lineCode?: string, callType?: number) => {
        return service({
            url: '/Call/GetCallingRecords',
            params: { lineCode: lineCode, orderByBeginTimeDescending: false }
        })
    },

    /**
     * 获取是否安装了呼叫系统
     */

    hasCallButtonSystem: (lineCode?: string) => {
        return service({
            url: '/Call/HasCallButtonSystem',
            params: { lineCode: lineCode }
        })
    }

}

export default Call



