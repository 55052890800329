import React from 'react';
import './index.scss';
import { Tabs, WhiteSpace } from 'antd-mobile';

const HyshenTest = () => {

    const tabs = [
        { title: '今日' },
        { title: '本周' },
        { title: '本月' },
        { title: '今年(按周)' },
        { title: '今年(按月)' },
        { title: '今年(按季度)' },
        { title: '累积' },
        { title: '上周' },
        { title: '上月' },
    ];
    return (
        <div className='hyshenTest-container' style={{ color: "#000" }}>
            <WhiteSpace />
            <Tabs tabs={tabs} renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3} />}>
            </Tabs>
            <WhiteSpace />
        </div>
    )
}

export { HyshenTest }